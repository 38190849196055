const CrudService = require('../../../../admin/source/js/lib/crud-service')

class DealService extends CrudService {
  get name() {
    return 'DealService'
  }
  get urlRoot() {
    return '/deal'
  }
}

module.exports = DealService
