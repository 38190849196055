const View = require('../views/viewer')
const debug = require('../../../../admin/source/js/lib/debug')('customer deal')

const formatCurrency = val =>
  `${new Intl.NumberFormat('en-GB', {
    style: 'currency',
    currency: 'GBP'
  }).format(val)}`.replace('.00', '')

const formatDate = (date, showTime) =>
  new Intl.DateTimeFormat('en-GB', {
    dateStyle: 'long',
    timeStyle: showTime ? 'medium' : undefined
  }).format(new Date(date))

module.exports = serviceLocator => {
  const router = serviceLocator.router

  router.route('customer/deal/:id', 'viewDealData', async id => {
    if (!serviceLocator.allow('customer', 'read')) return false
    debug('rendering customer deal view', id)

    const data = await new Promise((resolve, reject) => {
      serviceLocator.dealService.read(id, (err, data) => {
        if (err) {
          serviceLocator.router.trigger(
            'notFound',
            'There was an issue retrieving this deal'
          )
          return reject(err)
        }
        resolve(data)
      })
    })

    const vehicle = await new Promise((resolve, reject) => {
      serviceLocator.usedVehicleService.read(data.vehicle, (err, vehicle) => {
        if (err) {
          return reject(err)
        }
        resolve(vehicle)
      })
    })

    const vehicleLocation = await new Promise((resolve, reject) => {
      serviceLocator.dealershipService.read(
        vehicle.dealership,
        (err, dealer) => {
          if (err) {
            return reject(err)
          }
          resolve(dealer)
        }
      )
    })

    data.collectionLocation = await new Promise((resolve, reject) => {
      if (!data.collectionLocationId) {
        return resolve()
      }

      serviceLocator.dealershipService.read(
        data.collectionLocationId,
        (err, dealer) => {
          if (err) {
            return reject(err)
          }
          resolve(dealer)
        }
      )
    })

    const valuations = await Promise.all(
      data.tradeInVehicleIds.map(id => {
        return new Promise(resolve => {
          serviceLocator.valuationService.read(id, (err, valuation) => {
            if (err) {
              return resolve()
            }
            valuation.valuation = formatCurrency(valuation.valuation)
            valuation.url =
              serviceLocator.config.portalUrl +
              '/valuation/view/' +
              valuation._id
            resolve(valuation)
          })
        })
      })
    )

    const financeInfo = await new Promise((resolve, reject) => {
      if (!data.financeApplicationId) {
        return resolve()
      }

      serviceLocator.vehicleFinanceService.read(
        data.financeApplicationId,
        (err, finance) => {
          if (err) {
            return reject(err)
          }
          resolve(finance)
        }
      )
    })

    const getFinanceType = type => {
      if (type === 'cashPayment') return 'Cash'
      else return 'Finance'
    }

    const formattedData = {
      // Overview
      displayDate: formatDate(data.createdDate, true),
      dealId: data.dealId,
      state: data.state,

      // User Information
      userInfo: {
        Name: `${data.title} ${data.firstName} ${data.lastName}`,
        Email: data.emailAddress,
        Phone: data.phoneNumber
      },

      // Vehicle
      vehicle: {
        Registration: vehicle.registration.toUpperCase(),
        'Vehicle Type': vehicle.category,
        Make: vehicle.brand,
        Model: vehicle.model,
        Generation: vehicle.year,
        Derivative: vehicle.derivative,
        Trim: vehicle.trim,
        Location: vehicleLocation.location
      },

      // Deal Details
      tradeInVehicles: valuations,
      optionalExtras: data.optionalExtras,

      // Delivery
      deliveryType: data.deliveryType,
      deliveryDate: formatDate(data.deliveryDate),
      deliveryAddress: [
        data.deliveryAddress.addressLine1,
        data.deliveryAddress.addressLine2,
        data.deliveryAddress.addressLine3,
        data.deliveryAddress.town,
        data.deliveryAddress.county,
        data.deliveryAddress.postcode
      ].filter(addr => addr),
      collectionLocationId: data.collectionLocationId,
      collectionLocation: data.collectionLocation,

      // FINANCE
      finance: {
        Type: getFinanceType(data.financeType)
      }
    }

    if (financeInfo) {
      formattedData.finance['Finance ID'] =
        financeInfo.financeDetails.applicationNumber
      formattedData.finance.Status = financeInfo.financeDetails.status
      formattedData.finance.Link = `/customer/finance/${financeInfo._id}`
    }

    const view = new View(serviceLocator, formattedData, 'deal')
    router.render(view)
  })
}
