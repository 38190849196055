const schemata = require('schemata')

const miniReservation = () =>
  schemata({
    name: 'reservationItem',
    properties: {
      vehicleId: { type: String },
      reservationId: { type: String }
    }
  })

module.exports = miniReservation
