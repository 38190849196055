module.exports=[
  {
    "id": 1332,
    "range": "181"
  },
  {
    "id": 3494,
    "range": "ABT e-Transporter Kombi"
  },
  {
    "id": 3493,
    "range": "ABT e-Transporter Panel Van"
  },
  {
    "id": 2627,
    "range": "All New Magotan"
  },
  {
    "id": 1553,
    "range": "Amarok"
  },
  {
    "id": 2714,
    "range": "Arteon Mark 1 (2017 - 2020)"
  },
  {
    "id": 3357,
    "range": "Arteon Mark 1 Facelift (2020)"
  },
  {
    "id": 3359,
    "range": "Arteon Shooting Brake Mark 1 (2020)"
  },
  {
    "id": 1731,
    "range": "Beetle Cabriolet Mark 1 (2003-2011)"
  },
  {
    "id": 2385,
    "range": "Beetle Cabriolet Mark 2 (2011 - 2016)"
  },
  {
    "id": 2591,
    "range": "Beetle Cabriolet Mark 2 Facelift (2016)"
  },
  {
    "id": 1346,
    "range": "Beetle Mark 1 (1998 - 2011)"
  },
  {
    "id": 1431,
    "range": "Beetle Mark 2 (2011 - 2016)"
  },
  {
    "id": 2590,
    "range": "Beetle Mark 2 Facelift (2016)"
  },
  {
    "id": 1333,
    "range": "Bora"
  },
  {
    "id": 1334,
    "range": "Buggy"
  },
  {
    "id": 2631,
    "range": "C-TREK"
  },
  {
    "id": 3477,
    "range": "Caddy California"
  },
  {
    "id": 3668,
    "range": "Caddy California Maxi"
  },
  {
    "id": 3478,
    "range": "Caddy Cargo"
  },
  {
    "id": 3569,
    "range": "Caddy Cargo Maxi"
  },
  {
    "id": 3128,
    "range": "Caddy Cargo Maxi Kombi"
  },
  {
    "id": 2322,
    "range": "Caddy Passenger Carrier"
  },
  {
    "id": 3134,
    "range": "Caddy Passenger Carrier Maxi"
  },
  {
    "id": 3191,
    "range": "Caddy Xtra"
  },
  {
    "id": 3192,
    "range": "California \"30 years\""
  },
  {
    "id": 3154,
    "range": "California Beach"
  },
  {
    "id": 3351,
    "range": "California Coast"
  },
  {
    "id": 3155,
    "range": "California Ocean"
  },
  {
    "id": 3751,
    "range": "California 6.1"
  },
  {
    "id": 3352,
    "range": "California 6.1 Ocean"
  },
  {
    "id": 3163,
    "range": "Caravelle Executive"
  },
  {
    "id": 3165,
    "range": "Caravelle SE"
  },
  {
    "id": 3752,
    "range": "Caravelle 6.1"
  },
  {
    "id": 1630,
    "range": "CC Mark 1 (2008 - 2011)"
  },
  {
    "id": 2369,
    "range": "CC Mark 2 (2011 - 2017)"
  },
  {
    "id": 1336,
    "range": "Corrado"
  },
  {
    "id": 3146,
    "range": "Crafter Box / Luton"
  },
  {
    "id": 3148,
    "range": "Crafter Dropside"
  },
  {
    "id": 3147,
    "range": "Crafter Other Conversions"
  },
  {
    "id": 1369,
    "range": "Crafter Panel Van"
  },
  {
    "id": 3151,
    "range": "Crafter Tipper"
  },
  {
    "id": 3296,
    "range": "e-Crafter"
  },
  {
    "id": 2329,
    "range": "e-Golf Mark 7 (2014 - 2017)"
  },
  {
    "id": 3102,
    "range": "e-Golf Mark 7 Facelift (2017)"
  },
  {
    "id": 2272,
    "range": "e-up! Mark 1 (2014-2016)"
  },
  {
    "id": 2592,
    "range": "e-up! Mark 1 Facelift (2016-2019)"
  },
  {
    "id": 3276,
    "range": "e-up! Mark 1 Facelift 2 (2020)"
  },
  {
    "id": 1337,
    "range": "EOS Mark 1 (2003 - 2006)"
  },
  {
    "id": 2355,
    "range": "EOS Mark 2 (2006 - 2010)"
  },
  {
    "id": 1338,
    "range": "Fox Mark 1 (2006 - 2012)"
  },
  {
    "id": 2167,
    "range": "Golf Cabriolet Exclusive"
  },
  {
    "id": 1809,
    "range": "Golf Cabriolet Mark 6 (2011 - 2015)"
  },
  {
    "id": 2389,
    "range": "Golf Estate Mark 4 (1998 - 2006)"
  },
  {
    "id": 2390,
    "range": "Golf Estate Mark 5 (2006-2009)"
  },
  {
    "id": 2391,
    "range": "Golf Estate Mark 6 (2009 - 2014)"
  },
  {
    "id": 2392,
    "range": "Golf Estate Mark 7 (2014 - 2017)"
  },
  {
    "id": 2900,
    "range": "Golf Estate Mark 7 Facelift (2017-2020)"
  },
  {
    "id": 3387,
    "range": "Golf Estate Mark 8 (2020)"
  },
  {
    "id": 1807,
    "range": "Golf GTD Mark 4 (1998 - 2004)"
  },
  {
    "id": 2401,
    "range": "Golf GTD Mark 5 (2004 - 2009)"
  },
  {
    "id": 2402,
    "range": "Golf GTD Mark 6 (2009 - 2013)"
  },
  {
    "id": 2403,
    "range": "Golf GTD Mark 7 (2013-2017)"
  },
  {
    "id": 2902,
    "range": "Golf GTD Mark 7 Facelift (2017-2020)"
  },
  {
    "id": 3385,
    "range": "Golf GTD Mark 8 (2020)"
  },
  {
    "id": 2168,
    "range": "Golf GTE Mark 4 (1998 - 2004)"
  },
  {
    "id": 2405,
    "range": "Golf GTE Mark 5 (2004 - 2009)"
  },
  {
    "id": 2406,
    "range": "Golf GTE Mark 6 (2009 - 2013)"
  },
  {
    "id": 2407,
    "range": "Golf GTE Mark 7 (2013-2017)"
  },
  {
    "id": 2903,
    "range": "Golf GTE Mark 7 Facelift (2017-2020)"
  },
  {
    "id": 3360,
    "range": "Golf GTE Mark 8 (2020)"
  },
  {
    "id": 1808,
    "range": "Golf GTI Mark 4 (1998 - 2004)"
  },
  {
    "id": 2397,
    "range": "Golf GTI Mark 5 (2004 - 2009)"
  },
  {
    "id": 2398,
    "range": "Golf GTI Mark 6 (2009 - 2013)"
  },
  {
    "id": 2399,
    "range": "Golf GTI Mark 7 (2013-2017)"
  },
  {
    "id": 2901,
    "range": "Golf GTI Mark 7 Facelift (2017-2020)"
  },
  {
    "id": 3386,
    "range": "Golf GTI Mark 8 (2020)"
  },
  {
    "id": 1371,
    "range": "Golf Mark 4 (1998 - 2004)"
  },
  {
    "id": 1372,
    "range": "Golf Mark 5 (2004 - 2008)"
  },
  {
    "id": 1428,
    "range": "Golf Mark 6 (2008 - 2013)"
  },
  {
    "id": 1712,
    "range": "Golf Mark 7 (2013 - 2017)"
  },
  {
    "id": 2589,
    "range": "Golf Mark 7 Facelift (2017-2019)"
  },
  {
    "id": 3285,
    "range": "Golf Mark 8 (2020)"
  },
  {
    "id": 1450,
    "range": "Golf Plus Mark 1 (2005 - 2009)"
  },
  {
    "id": 2394,
    "range": "Golf Plus Mark 2 (2009 - 2014)"
  },
  {
    "id": 2330,
    "range": "Golf R Mark 4 (1998 - 2004)"
  },
  {
    "id": 2409,
    "range": "Golf R Mark 5 (2004 - 2009)"
  },
  {
    "id": 2410,
    "range": "Golf R Mark 6 (2009 - 2013)"
  },
  {
    "id": 2411,
    "range": "Golf R Mark 7 (2013-2017)"
  },
  {
    "id": 2904,
    "range": "Golf R Mark 7 Facelift (2017-2020)"
  },
  {
    "id": 3476,
    "range": "Golf R Mark 8 (2020)"
  },
  {
    "id": 1805,
    "range": "Golf SV Mark 1 (2014 - 2017)"
  },
  {
    "id": 2915,
    "range": "Golf SV Mark 2 (2017)"
  },
  {
    "id": 3348,
    "range": "Grand California 600"
  },
  {
    "id": 3349,
    "range": "Grand California 680"
  },
  {
    "id": 3769,
    "range": "ID. Buzz"
  },
  {
    "id": 3771,
    "range": "ID. Buzz Cargo"
  },
  {
    "id": 3295,
    "range": "ID.3 Mark 1 (2020)"
  },
  {
    "id": 3463,
    "range": "ID.4 Mark 1 (2021)"
  },
  {
    "id": 3626,
    "range": "ID.5 Mark 1 (2022)"
  },
  {
    "id": 1340,
    "range": "Iltis"
  },
  {
    "id": 1341,
    "range": "Jetta Mark 1 (2006 - 2010)"
  },
  {
    "id": 2383,
    "range": "Jetta Mark 2 (2010)"
  },
  {
    "id": 1342,
    "range": "Karmann Ghia"
  },
  {
    "id": 1345,
    "range": "Lupo"
  },
  {
    "id": 3190,
    "range": "Multivan 6.1"
  },
  {
    "id": 3676,
    "range": "Multivan Energetic"
  },
  {
    "id": 3677,
    "range": "Multivan Life"
  },
  {
    "id": 3678,
    "range": "Multivan Style"
  },
  {
    "id": 2628,
    "range": "New Sagitar"
  },
  {
    "id": 1811,
    "range": "Passat Alltrack"
  },
  {
    "id": 2041,
    "range": "Passat CC"
  },
  {
    "id": 3093,
    "range": "Passat Estate GTE Mark 7 (2010-2015)"
  },
  {
    "id": 3094,
    "range": "Passat Estate GTE Mark 8 (2015 - 2019)"
  },
  {
    "id": 3220,
    "range": "Passat Estate GTE Mark 8 Facelift (2019)"
  },
  {
    "id": 2373,
    "range": "Passat Estate Mark 6 (2004 - 2010)"
  },
  {
    "id": 2374,
    "range": "Passat Estate Mark 7 (2010 - 2015)"
  },
  {
    "id": 2375,
    "range": "Passat Estate Mark 8 (2015 - 2019)"
  },
  {
    "id": 3219,
    "range": "Passat Estate Mark 8 Facelift (2019)"
  },
  {
    "id": 3105,
    "range": "Passat GTE Mark 7 (2010-2015)"
  },
  {
    "id": 3106,
    "range": "Passat GTE Mark 8 (2015 - 2019)"
  },
  {
    "id": 3221,
    "range": "Passat GTE Mark 8 Facelift (2019)"
  },
  {
    "id": 2377,
    "range": "Passat Saloon Mark 5 (2000 - 2005)"
  },
  {
    "id": 2378,
    "range": "Passat Saloon Mark 6 (2005 - 2010)"
  },
  {
    "id": 2379,
    "range": "Passat Saloon Mark 7 (2010 - 2015)"
  },
  {
    "id": 2380,
    "range": "Passat Saloon Mark 8 (2015 - 2019)"
  },
  {
    "id": 3218,
    "range": "Passat Saloon Mark 8 Facelift (2019)"
  },
  {
    "id": 1348,
    "range": "Phaeton Mark 1 (2003 - 2010)"
  },
  {
    "id": 2916,
    "range": "Phaeton Mark 2 (2010 - 2016)"
  },
  {
    "id": 1374,
    "range": "Polo Mark 4 (2002 - 2009)"
  },
  {
    "id": 2413,
    "range": "Polo Mark 5 (2010-2014)"
  },
  {
    "id": 2917,
    "range": "Polo Mark 5 Facelift (2014 - 2017)"
  },
  {
    "id": 2912,
    "range": "Polo Mark 6 (2017-2021)"
  },
  {
    "id": 3613,
    "range": "Polo Mark 6 Facelift (2021)"
  },
  {
    "id": 2042,
    "range": "Routan"
  },
  {
    "id": 2629,
    "range": "Sagitar GLI"
  },
  {
    "id": 2630,
    "range": "Sagitar R-Line"
  },
  {
    "id": 1350,
    "range": "Santana"
  },
  {
    "id": 1351,
    "range": "Scirocco Mark 1 (2008)"
  },
  {
    "id": 1352,
    "range": "Sharan Mark 1 (2000 - 2010)"
  },
  {
    "id": 2360,
    "range": "Sharan Mark 2 (2010)"
  },
  {
    "id": 3085,
    "range": "T-Cross Mark 1 (2019)"
  },
  {
    "id": 3280,
    "range": "T-Roc Cabriolet Mark 1 (2020-2022)"
  },
  {
    "id": 3681,
    "range": "T-Roc Cabriolet Mark 1 Facelift (2022)"
  },
  {
    "id": 3652,
    "range": "T7 Multivan"
  },
  {
    "id": 2769,
    "range": "T-Roc Mark 1 (2017-2022)"
  },
  {
    "id": 3680,
    "range": "T-Roc Mark 1 Facelift (2022)"
  },
  {
    "id": 3607,
    "range": "Taigo Mark 1 (2021)"
  },
  {
    "id": 1365,
    "range": "Taro"
  },
  {
    "id": 2918,
    "range": "Tiguan Allspace Mark 1 (2018-2021)"
  },
  {
    "id": 3575,
    "range": "Tiguan Allspace Mark 1 Facelift (2021)"
  },
  {
    "id": 1418,
    "range": "Tiguan Mark 1 (2008 - 2012)"
  },
  {
    "id": 2364,
    "range": "Tiguan Mark 1 Facelift (2012-2016)"
  },
  {
    "id": 2365,
    "range": "Tiguan Mark 2 (2016-2020)"
  },
  {
    "id": 3388,
    "range": "Tiguan Mark 2 Facelift (2020)"
  },
  {
    "id": 1366,
    "range": "Touareg Mark 1 (2003 - 2009)"
  },
  {
    "id": 2362,
    "range": "Touareg Mark 2 (2010-2018)"
  },
  {
    "id": 3030,
    "range": "Touareg Mark 3 (2018)"
  },
  {
    "id": 1367,
    "range": "Touran Mark 1 (2003 - 2010)"
  },
  {
    "id": 2357,
    "range": "Touran Mark 2 (2010 - 2016)"
  },
  {
    "id": 2358,
    "range": "Touran Mark 3 (2016)"
  },
  {
    "id": 3753,
    "range": "Single Cab Dropside 6.1"
  },
  {
    "id": 3136,
    "range": "Transporter Conversions"
  },
  {
    "id": 3137,
    "range": "Transporter Kombi"
  },
  {
    "id": 3138,
    "range": "Transporter Panel Van"
  },
  {
    "id": 3143,
    "range": "Transporter Shuttle S"
  },
  {
    "id": 3144,
    "range": "Transporter Shuttle SE"
  },
  {
    "id": 3141,
    "range": "Transporter Window Van"
  },
  {
    "id": 1589,
    "range": "up! Mark 1 (2011-2016)"
  },
  {
    "id": 2593,
    "range": "up! Mark 1 Facelift (2016-2019)"
  },
  {
    "id": 3275,
    "range": "up! Mark 1 Facelift 2 (2020)"
  },
  {
    "id": 1368,
    "range": "Vento"
  },
  {
    "id": 2045,
    "range": "XL1"
  }
]
