const BaseListItemView = require('../../base/views/list-item')
const compileJade = require('browjadify-compile')
const join = require('path')
const template = function template(locals) {
var buf = [];
var jade_mixins = {};
var jade_interp;
;var locals_for_with = (locals || {});(function (allowed, data, format) {
buf.push("<div class=\"list-item list-item-detail\"><div class=\"image-wrapper\"><i" + (jade.attr("style", 'background-image: url("' + data.previewImageUrlSmall + '");', true, false)) + " class=\"image image-detail\"></i></div><div class=\"list-item-header\"><div class=\"list-item-actions\">");
if ( allowed('dealership', 'delete'))
{
buf.push("<label class=\"list-item-select\"><input type=\"checkbox\" name=\"name\" class=\"js-select\"/></label>");
}
buf.push("<div class=\"btn-group\"><a data-toggle=\"dropdown\" href=\"#\" class=\"btn btn--small dropdown-toggle\"><span class=\"caret\"></span></a><ul class=\"dropdown-menu pull-right\"><li><a class=\"js-show-revisions\">Show Revisions</a></li><li><a class=\"js-show-calendar\">Show Calendar</a></li></ul></div></div>");
var addressLine1= data.addressLine1 && ` - ${data.addressLine1}` || ''
var postcode = data.postcode && `, ${data.postcode}` || ''
var title = `${data.location}${addressLine1}${postcode}`
if ( allowed('dealership', 'update'))
{
buf.push("<h2><a" + (jade.attr("href", '/dealerships/' + (data._id) + '/form', true, false)) + " class=\"js-edit\">" + (jade.escape(null == (jade_interp = title) ? "" : jade_interp)) + "</a></h2>");
}
else
{
buf.push("<h2>" + (jade.escape(null == (jade_interp = title) ? "" : jade_interp)) + "</h2>");
}
buf.push("</div><div class=\"list-item-content\"><dl>");
if ((data._url))
{
buf.push("<dt>Slug:</dt><dd><a" + (jade.attr("href", data._url, true, false)) + " target=\"_blank\">" + (jade.escape((jade_interp = data.slug) == null ? '' : jade_interp)) + "</a></dd>");
}
buf.push("<dt>Created:</dt><dd>" + (jade.escape(null == (jade_interp = format(data.createdDate, 'calendar')) ? "" : jade_interp)) + "</dd></dl></div></div>");}.call(this,"allowed" in locals_for_with?locals_for_with.allowed:typeof allowed!=="undefined"?allowed:undefined,"data" in locals_for_with?locals_for_with.data:typeof data!=="undefined"?data:undefined,"format" in locals_for_with?locals_for_with.format:typeof format!=="undefined"?format:undefined));;return buf.join("");
}
const getImageUrl = require('../../lib/default-image-url-generator')
const modal = require('modal')
const CalendarView = require('./calendar')
class ListItemView extends BaseListItemView {
  constructor(...args) {
    super(...args)
    this.$el.on(
      'click',
      '.js-show-revisions',
      this.emit.bind(this, 'showRevisions')
    )
    this.$el.on('click', '.js-show-calendar', this.showCalendar.bind(this))
  }

  serialize() {
    const data = this.model.toJSON()
    data.previewImageUrlSmall = getImageUrl({
      model: this.model,
      width: 80,
      height: 80,
      mode: 'pad'
    })

    data.hasSection = typeof data.__fullUrlPath !== 'undefined'

    return data
  }

  showCalendar() {
    const data = this.serialize()

    const calendarView = new CalendarView({
      link: `${this.serviceLocator.config.apiUrl}/dealerships/${
        data._id
      }/ecommerce-calendar`
    })
    modal({
      title: 'Subcribe to calendar',
      className: 'modal-asset-view wide',
      content: calendarView.render().$el,
      buttons: [{ text: 'Close', event: 'cancel', className: 'btn' }]
    })
    return this
  }

  render() {
    const data = this.serialize()
    const url = this.serviceLocator.config.url
    data._url =
      url && `${this.serviceLocator.config.url}/locations/${data.slug}`

    this.$el.empty().append(
      template({
        data,
        allowed: this.serviceLocator.allowed,
        format: this.serviceLocator.format
      })
    )

    this.$el.find('.js-tooltip-trigger').tooltip({ html: true })
    return this
  }

  get template() {
    return template
  }
}

module.exports = ListItemView
