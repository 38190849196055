const CrudService = require('../../../../admin/source/js/lib/crud-service')

class VehicleFinanceService extends CrudService {
  get name() {
    return 'VehicleFinance'
  }
  get urlRoot() {
    return '/vehicle-finance'
  }
}

module.exports = VehicleFinanceService
