import currencyFormatter from './currency-formatter'

const formatPrice = value => currencyFormatter({ value, currency: 'GBP' })

export default function formatFinanceQuote(finance) {
  const name = finance.product.name

  const payments = {}
  const fees = {}

  for (const payment of finance.payments || []) {
    payments[payment.description] = formatPrice(payment.amount)
  }

  for (const fee of finance.fees || []) {
    fees[fee.name] = formatPrice(fee.amount)
  }

  const infoItems = {
    'Cash Deposit': formatPrice(finance.deposit.cash || 0),
    'Total Trade-ins': formatPrice(finance.deposit.partExchange || 0),
    'Extra Contributions': formatPrice(finance.deposit.contributions || 0),
    [`Total Deposit ${
      finance.deposit.partExchange ? '(w/ trade in}' : ''
    }`]: formatPrice(finance.totalDeposit),
    'Amount of Credit': formatPrice(finance.totalPriceExcludingDiscounts),
    'Total Amount Payable': formatPrice(finance.totalAmountPayable),
    ...payments,
    AER: `${finance.aer}%`,
    APR: `${finance.apr}%`
  }

  return {
    name,
    infoItems,
    fees
  }
}
