module.exports=[
  {
    "id": 1183,
    "range": "Alhambra"
  },
  {
    "id": 1184,
    "range": "Altea"
  },
  {
    "id": 2348,
    "range": "Altea XL"
  },
  {
    "id": 2909,
    "range": "Arona"
  },
  {
    "id": 1185,
    "range": "Arosa"
  },
  {
    "id": 2299,
    "range": "Ateca"
  },
  {
    "id": 1186,
    "range": "Cordoba"
  },
  {
    "id": 3126,
    "range": "CUPRA Ateca"
  },
  {
    "id": 1481,
    "range": "Exeo"
  },
  {
    "id": 2756,
    "range": "Exeo ST"
  },
  {
    "id": 2932,
    "range": "Ibiza"
  },
  {
    "id": 2749,
    "range": "Ibiza 3 Door SC"
  },
  {
    "id": 2750,
    "range": "Ibiza 5 Door"
  },
  {
    "id": 2751,
    "range": "Ibiza ST"
  },
  {
    "id": 1188,
    "range": "Inca"
  },
  {
    "id": 2753,
    "range": "Leon 5 Door"
  },
  {
    "id": 2754,
    "range": "Leon Estate"
  },
  {
    "id": 3636,
    "range": "Leon Estate e-Hybrid"
  },
  {
    "id": 2752,
    "range": "Leon SC"
  },
  {
    "id": 2755,
    "range": "Leon X-PERIENCE"
  },
  {
    "id": 3635,
    "range": "Leon e-Hybrid"
  },
  {
    "id": 1190,
    "range": "Malaga"
  },
  {
    "id": 1191,
    "range": "Marbella"
  },
  {
    "id": 2743,
    "range": "Mii 3 Door"
  },
  {
    "id": 2744,
    "range": "Mii 5 Door"
  },
  {
    "id": 3234,
    "range": "Mii electric"
  },
  {
    "id": 3084,
    "range": "Tarraco"
  },
  {
    "id": 1192,
    "range": "Terra"
  },
  {
    "id": 1193,
    "range": "Toledo"
  }
]
