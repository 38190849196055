const View = require('ventnor')
const compileJade = require('browjadify-compile')
const join = require('path').join
const additionalDetailsContainerTemplate = function template(locals) {
var buf = [];
var jade_mixins = {};
var jade_interp;
;var locals_for_with = (locals || {});(function (allowed, autotraderData, index, type) {
jade_mixins["lineUpPanel"] = jade_interp = function(type){
var block = (this && this.block), attributes = (this && this.attributes) || {};
buf.push("<div id=\"section-details\"" + (jade.attr("data-field", type + 'Details', true, false)) + " class=\"panel panel-styled js-details-panel\"><div class=\"panel-header panel-header--justified\"><h2>" + (jade.escape(null == (jade_interp = (type.charAt(0).toUpperCase() + type.slice(1)) + ' Details') ? "" : jade_interp)) + "</h2><div class=\"control-group\">");
if ( allowed('features', 'create'))
{
buf.push("<div class=\"form-field\"><label><input type=\"checkbox\" name=\"export-field\"" + (jade.attr("value", type, true, false)) + " class=\"control control--boolean\"/><span>Export to Feature Set</span></label></div>");
}
if ( autotraderData.features)
{
buf.push("<button type=\"button\"" + (jade.attr("data-type", type, true, false)) + " class=\"btn js-add-autotrader-features\">Add AutoTrader Features</button>");
}
buf.push("<button type=\"button\"" + (jade.attr("data-type", type, true, false)) + " class=\"btn js-clear-items\">Clear Items</button><button type=\"button\"" + (jade.attr("data-type", type, true, false)) + " class=\"btn js-add-standard\">Add Standard Feature</button></div></div><div class=\"panel-content\"><div" + (jade.attr("id", 'field--' + type + 'details', true, false)) + " class=\"form-row is-hidden\"><div class=\"js-error\"></div><table" + (jade.cls(['js-' + type + '-details'], [true])) + "></table></div><button type=\"button\"" + (jade.attr("data-type", type, true, false)) + " class=\"btn js-add-item\">Add Item</button><p></p></div></div>");
};
buf.push("<div id=\"section-star-points\" data-field=\"starPoints\" class=\"panel panel-styled js-details-panel\"><div class=\"panel-header panel-header--justified\"><h2> \nStar Points (<span class=\"js-star-count\">0</span>" + (jade.escape((jade_interp = ' ') == null ? '' : jade_interp)) + "\nout of 10)</h2><div class=\"control-group\"><button type=\"button\" data-type=\"features\" class=\"btn js-clear-items\">Clear Items</button></div></div><div class=\"panel-content\"><div id=\"field--features\" class=\"form-row is-hidden\"><div class=\"js-error\"></div><div class=\"js-star-points\"></div></div><p></p></div></div><div class=\"panel panel-styled panel-floating hidden js-feature-set-panel\"><div class=\"panel-header panel-header--justified\"><h2>Creating Feature Set</h2></div><div class=\"panel-content\"><div class=\"form-row\"><input type=\"text\" name=\"feature-set-name\" class=\"control control--text js-feature-set-name\"/></div><div class=\"form-row form-actions\"><div class=\"btn-group\"><a" + (jade.attr("data-index", index, true, false)) + (jade.attr("data-type", type, true, false)) + " class=\"btn btn--notice js-create-feature-set\">Create</a><a" + (jade.attr("data-index", index, true, false)) + (jade.attr("data-type", type, true, false)) + " class=\"btn js-feature-set-cancel\">Cancel</a></div></div></div></div><div class=\"panel panel-styled\"><div class=\"panel-header panel-header--justified\"><h2>Import Feature Set</h2></div><div class=\"panel-content\"><div class=\"form-row\"><div class=\"js-select-feature-set\"></div></div><div class=\"form-row form-actions\"><a" + (jade.attr("data-index", index, true, false)) + (jade.attr("data-type", type, true, false)) + " class=\"btn btn--notice js-import-feature-set\">Import</a></div></div></div>");
jade_mixins["lineUpPanel"]('safety');
jade_mixins["lineUpPanel"]('interior');
jade_mixins["lineUpPanel"]('exterior');
jade_mixins["lineUpPanel"]('technical');}.call(this,"allowed" in locals_for_with?locals_for_with.allowed:typeof allowed!=="undefined"?allowed:undefined,"autotraderData" in locals_for_with?locals_for_with.autotraderData:typeof autotraderData!=="undefined"?autotraderData:undefined,"index" in locals_for_with?locals_for_with.index:typeof index!=="undefined"?index:undefined,"type" in locals_for_with?locals_for_with.type:typeof type!=="undefined"?type:undefined));;return buf.join("");
}
const detailsItemTemplate = function template(locals) {
var buf = [];
var jade_mixins = {};
var jade_interp;
;var locals_for_with = (locals || {});(function (disabled, index, label, starred, type, value) {
buf.push("<tr" + (jade.attr("data-type", type, true, false)) + (jade.cls(['js-additional-item','js-' + type], [null,true])) + "><td" + (jade.attr("id", 'field--item-move-' + index, true, false)) + (jade.attr("data-field", 'item-move-' + index, true, false)) + " class=\"form-row\"><span class=\"label label--large sort-handle js-sort-handle\"><i class=\"icon icon--hamburger\">Move</i></span><a" + (jade.attr("data-index", index, true, false)) + (jade.attr("data-type", type, true, false)) + " class=\"spec-button-link btn js-move-bottom\"><p class=\"spec-button\">↧</p></a><a" + (jade.attr("data-index", index, true, false)) + (jade.attr("data-type", type, true, false)) + " class=\"spec-button-link btn js-move-top\"><p class=\"spec-button\">↥</p></a></td>");
const isTechnical = type === 'technical'
if ( isTechnical)
{
buf.push("<td" + (jade.attr("id", 'field--item-label-' + index, true, false)) + (jade.attr("data-field", 'item-label-' + index, true, false)) + " class=\"form-row\"><input type=\"text\" name=\"itemLabel\"" + (jade.attr("value", label, true, false)) + (jade.attr("data-index", index, true, false)) + " data-type=\"label\" placeholder=\"Label\"" + (jade.attr("disabled", !isTechnical, true, false)) + (jade.cls(['control','control--text',!isTechnical?'grey-out-label': ''], [null,null,true])) + "/></td>");
}
buf.push("<td" + (jade.attr("id", 'field--item-value-' + index, true, false)) + (jade.attr("data-field", 'item-value-' + index, true, false)) + " class=\"form-row\"><input type=\"text\" name=\"itemValue\"" + (jade.attr("value", value, true, false)) + (jade.attr("data-index", index, true, false)) + " data-type=\"value\" placeholder=\"Value\" class=\"control control--text\"/></td><td" + (jade.attr("id", 'field--item-star-' + index, true, false)) + (jade.attr("data-field", 'item-star-' + index, true, false)) + " class=\"form-row form-row-boolean\"><div class=\"form-field form-row-full-width\"><label><input type=\"checkbox\"" + (jade.attr("name", 'star-'+index, true, false)) + (jade.attr("id", 'star-'+index, true, false)) + (jade.attr("checked", starred, true, false)) + (jade.attr("disabled", disabled, true, false)) + (jade.attr("data-index", index, true, false)) + (jade.attr("data-type", type, true, false)) + " class=\"hidden control control--boolean js-star-item\"/>");
const icon = starred ? 'icon--star--filled' : 'icon--star'
if ( disabled)
{
buf.push("<span data-toggle=\"tooltip\" title=\"Ten items have been starred already, please unstar another star first\" class=\"js-tooltip-trigger\"><i" + (jade.cls(['icon',icon], [null,true])) + "></i></span>");
}
else
{
buf.push("<i" + (jade.cls(['icon',icon], [null,true])) + "></i>");
}
buf.push("</label></div></td><td" + (jade.attr("id", 'field--item-remove-' + index, true, false)) + (jade.attr("data-field", 'item-remove-' + index, true, false)) + " class=\"form-row\"><div class=\"list-item-actions\"><div class=\"btn-group\"><a" + (jade.attr("data-index", index, true, false)) + (jade.attr("data-type", type, true, false)) + " class=\"btn js-remove-item\"><i class=\"icon icon--cross--dark\"></i></a></div></div></td></tr>");}.call(this,"disabled" in locals_for_with?locals_for_with.disabled:typeof disabled!=="undefined"?disabled:undefined,"index" in locals_for_with?locals_for_with.index:typeof index!=="undefined"?index:undefined,"label" in locals_for_with?locals_for_with.label:typeof label!=="undefined"?label:undefined,"starred" in locals_for_with?locals_for_with.starred:typeof starred!=="undefined"?starred:undefined,"type" in locals_for_with?locals_for_with.type:typeof type!=="undefined"?type:undefined,"value" in locals_for_with?locals_for_with.value:typeof value!=="undefined"?value:undefined));;return buf.join("");
}
const starPointTemplate = function template(locals) {
var buf = [];
var jade_mixins = {};
var jade_interp;
;var locals_for_with = (locals || {});(function (index, point) {
buf.push("<div class=\"list-item\"><div class=\"list-item-header\"><div class=\"list-item-leading-actions\"><span class=\"label label--large sort-handle js-sort-handle\"><i class=\"icon icon--hamburger\">Move</i></span></div><div class=\"list-item-actions\"><div class=\"control-group\"><div class=\"btn-group\"><a" + (jade.attr("data-index", index, true, false)) + " data-type=\"features\" class=\"btn btn--small js-delete-star\"><i class=\"icon icon--cross--dark\"></i></a></div></div></div><h2 class=\"js-star-title\">" + (jade.escape(null == (jade_interp = point) ? "" : jade_interp)) + "</h2></div></div>");}.call(this,"index" in locals_for_with?locals_for_with.index:typeof index!=="undefined"?index:undefined,"point" in locals_for_with?locals_for_with.point:typeof point!=="undefined"?point:undefined));;return buf.join("");
}
const notify = require('../../notification/foreground')
const BaseSelect = require('../../base/views/select')
const StandardDataModal = require('../../standard-data/views/feature-picker')
const AutotraderFeaturePicker = require('./autotrader-feature-picker')
const FeaturesView = require('../../features/views/additional-details')
const modal = require('modal')
const Model = require('merstone')

class AdditionalDetailsView extends View {
  constructor(serviceLocator, model, autotraderData) {
    super(serviceLocator)
    this.model = model
    this.autotraderData = autotraderData
    this.itemCount = {
      safety: 0,
      interior: 0,
      exterior: 0,
      technical: 0
    }
    this.propertyNames = ['safety', 'interior', 'exterior', 'technical']
    this.model.on('change:features', this.updateStars.bind(this))
    this.$el.on(
      'change',
      'input[name="export-field"]',
      this.updateExportPopover.bind(this)
    )
    this.$el.on(
      'click',
      '.js-feature-set-cancel',
      this.resetExportPopover.bind(this)
    )
    this.$el.on(
      'click',
      '.js-create-feature-set',
      this.exportFeatureSet.bind(this)
    )
    this.$el.on(
      'click',
      '.js-import-feature-set',
      this.importFeatureSet.bind(this)
    )
    this.$el.on(
      'click',
      '.js-add-autotrader-features',
      this.addAutotraderFeatures.bind(this)
    )

    this.$el.on('click', '.js-add-standard', this.chooseFeatures.bind(this))

    const features = this.model.get('features').filter(feature => feature)
    this.model.set('features', features)
  }

  chooseFeatures(e) {
    const type = $(e.currentTarget).data('type')

    const modal = new StandardDataModal(this.serviceLocator, type).render()

    modal.on('add', () => {
      modal.selectedCollection.forEach(model => {
        const items = this.model.get(type)
        items.push({ label: model.get('label'), value: model.get('value') })
        this.model.set(type, items)
        this.addItem(model.get('label'), model.get('value'), false, type, true)
      })
    })
  }

  resetExportPopover() {
    this.$el.find('input[name="export-field"]:checked').prop('checked', false)
    this.updateExportPopover()
  }

  exportFeatureSet() {
    const id = this.model.get('_id')
    const items = this.$el
      .find('input[name="export-field"]:checked')
      .toArray()
      .map(box => box.value)

    const name = this.$el.find('.js-feature-set-name').val()

    this.serviceLocator.featuresService.createFromVehicle(
      id,
      name,
      items,
      (err, body) => {
        if (err || body.status !== 'OK') {
          return alert('Failed to export')
        }

        notify('Exported', 'save')
        this.resetExportPopover()
      }
    )
  }

  importFeatureSet() {
    this.serviceLocator.featuresService.read(
      this.featureSet,
      (err, features) => {
        if (err) return alert('Could not read feature set')

        const model = new Model(this.serviceLocator, features)
        const featureView = new FeaturesView(this.serviceLocator, model, true)

        modal({
          title: 'Choose Features',
          className: 'wide',
          content: featureView.render().$el,
          buttons: [
            { text: 'Cancel', className: 'btn', event: 'cancel' },
            {
              text: 'Add Features',
              className: 'btn btn--error',
              event: 'confirm'
            }
          ]
        }).on('confirm', () => {
          this.propertyNames.forEach(item => {
            model.get(item).forEach(({ label, value }) => {
              const values = this.model.get(item)
              values.push({ label, value })
              this.model.set(item, values)
              this.addItem(label, value, false, item)
            })
          })
        })
      }
    )
  }

  addAutotraderFeatures(e) {
    const type = $(e.currentTarget).data('type')

    const featuresModal = new AutotraderFeaturePicker(
      this.serviceLocator,
      type,
      this.autotraderData
    ).render()

    featuresModal.on('add', () => {
      const items = this.model.get(type)

      featuresModal.selectedFeatures.forEach(featureName => {
        items.push({ value: featureName, label: featureName })
        this.model.set(type, items)
        this.addItem(featureName, featureName, false, type, true)
      })
    })
  }

  updateExportPopover() {
    const items = this.$el
      .find('input[name="export-field"]:checked')
      .toArray()
      .map(box => box.value)

    const popover = this.$el.find('.js-feature-set-panel')
    const { brand, model, derivative, year } = this.model.toJSON()
    const numFeatures = items.reduce(
      (num, item) => num + this.itemCount[item],
      0
    )

    const features =
      items.length < this.propertyNames.length ? items.join(', ') : 'All'
    if (items.length && numFeatures > 0) {
      popover.removeClass('hidden')
      this.$el
        .find('.js-feature-set-name')
        .val(`${brand} ${model} ${derivative} ${year} features: ${features}`)
    } else {
      popover.addClass('hidden')
    }
  }

  render() {
    this.$el.append(
      additionalDetailsContainerTemplate({
        allowed: this.serviceLocator.allowed,
        autotraderData: this.autotraderData
      })
    )

    this.updateStars()
    this.renderStarPoints()

    this.propertyNames.map(propName => this.renderItems(propName))

    this.$el
      .find('.js-clear-items')
      .on('click', this.handleClearItems.bind(this))

    this.$el.find('.js-add-item').on('click', this.handleAddNewItem.bind(this))

    this.renderFeatureSelect()
    return this
  }

  renderFeatureSelect() {
    const featureSelect = new BaseSelect(this.serviceLocator, {
      service: 'featuresService',
      limitToOneValue: true,
      singular: 'feature set',
      plural: 'feature sets',
      textProperty: 'name',
      sortProperty: 'name'
    })

    this.$el.find('.js-select-feature-set').append(featureSelect.render().$el)
    featureSelect.on('change', featureSet => (this.featureSet = featureSet))
    this.attachView(featureSelect)
  }

  updateStars() {
    this.$el.find('.js-star-count').text(this.model.get('features').length)
    this.propertyNames.forEach(type => this.renderItems(type))
  }

  renderStarPoints() {
    this.$el.find('.js-star-points').empty()

    this.model.get('features').forEach((point, index) => {
      this.$el.find('.js-star-points').append(
        starPointTemplate({
          point,
          index
        })
      )
    })

    this.$el.find('.js-delete-star').on('click', e => {
      this.handleRemoveItem(e)
    })

    this.$el
      .find('.js-star-points')
      .sortable({
        handle: '.js-sort-handle',
        forcePlaceholderSize: 'true',
        cursor: 'move',
        cursorAt: { left: 30, top: 30 },
        tolerance: 'pointer'
      })
      .off('sortupdate')
      .on('sortupdate', () => {
        const order = []
        this.$el.find('.js-star-title').map((index, el) => {
          const newLength = order.push($(el).text())

          $(el)
            .parent()
            .find('.js-delete-star')
            .data('index', newLength - 1)
        })

        this.model.set('features', order)
      })
  }

  remapItems(type) {
    this.model.set(
      type,
      this.model.get(type).map((item, index) => {
        const { value } = item
        item.starPosition = this.model
          .get('features')
          .findIndex(star => star && star.includes(value))

        const tick = `.js-${type}-details #field--item-star-${index} .js-star-item`

        this.$el.find(tick).prop('checked', item.starPosition >= 0)

        return item
      })
    )
  }

  renderItems(type) {
    if (type === 'features') {
      return this.renderStarPoints()
    }

    this.remapItems(type)

    this.itemCount[type] = 0
    const item = '.js-' + type + '-details'
    this.$el.find(item).empty()
    const currentItems = this.model.get(type) || []

    for (let i = 0; i < currentItems.length; i++) {
      const currentItem = currentItems[i]
      const itemLabel = currentItem ? currentItem.label : null
      const itemValue = currentItem ? currentItem.value : null
      const starred = currentItem ? currentItem.starPosition >= 0 : null
      this.addItem(itemLabel, itemValue, starred, type)
    }

    this.$el
      .find(item)
      .sortable({
        handle: '.js-sort-handle',
        forcePlaceholderSize: 'true',
        cursor: 'move',
        cursorAt: { left: 30, top: 30 },
        tolerance: 'pointer'
      })
      .off('sortupdate')
      .on('sortupdate', () => {
        this.processItems(type)
        this.remapItems(type)
        this.renderItems(type)
      })

    this.$el.find('.js-tooltip-trigger').tooltip({ html: true })
  }

  processDetails() {
    this.propertyNames.forEach(this.processItems.bind(this))
  }

  processItems(type) {
    const items = []

    this.$el
      .find(`.js-additional-item[data-type='${type}']`)
      .map((index, el) => {
        const label = $(el)
          .find(`input[name='itemLabel']`)
          .val()
        const value = $(el)
          .find(`input[name='itemValue']`)
          .val()

        items.push({ label, value })
      })

    this.model.set(type, items)
  }

  addItem(itemLabel, itemValue, starred, type, newItem = false) {
    const index = newItem ? 0 : this.itemCount[type]++

    const disabled = !starred && this.model.get('features').length >= 10

    if (newItem) {
      this.$el.find('.js-' + type + '-details').prepend(
        detailsItemTemplate({
          label: itemLabel,
          value: itemValue,
          index,
          type,
          starred,
          disabled
        })
      )
    } else {
      this.$el.find('.js-' + type + '-details').append(
        detailsItemTemplate({
          label: itemLabel,
          value: itemValue,
          index,
          type,
          starred,
          disabled
        })
      )
    }

    this.$el.find('.js-' + type + '-details input[type=text]').off('change')
    this.$el
      .find('.js-' + type + '-details input[type=text]')
      .on('change', e => {
        const index = e.target.dataset.index
        const key = e.target.dataset.type
        const values = this.model.get(type)
        const currentValue = values[index]
        const itemObject = {}
        itemObject[key] = e.target.value

        values[index] = Object.assign({}, currentValue, itemObject)

        if (currentValue.starPosition >= 0) {
          const { value, starPosition } = currentValue

          const stars = this.model.get('features')
          stars[starPosition] = value
          this.model.set('features', stars)
          this.renderStarPoints()
        }

        this.model.set(type, values)
      })

    this.$el
      .find(`.js-${type}-details #field--item-star-${index} .js-star-item`)
      .off('change')
      .on('change', e => {
        this.handleToggleStar(e)
      })

    this.$el
      .find(`.js-${type}-details #field--item-move-${index} .js-move-top`)
      .off('click')
      .on('click', e => {
        this.handleMoveItem(e, true)
      })

    this.$el
      .find(`.js-${type}-details #field--item-move-${index} .js-move-bottom`)
      .off('click')
      .on('click', e => {
        this.handleMoveItem(e, false)
      })

    this.$el
      .find(`.js-${type} #field--item-remove-` + index + ' .js-remove-item')
      .off('click')
      .on('click', e => {
        this.handleRemoveItem(e)
      })
    if (newItem) {
      this.processItems(type)
      this.remapItems(type)
      this.renderItems(type)
    }
  }

  handleAddNewItem(e) {
    e.preventDefault()
    const $clickedEl = $(e.currentTarget)
    const type = $clickedEl.data('type')
    const items = this.model.get(type)
    items.push({ label: '', value: '' })
    this.model.set(type, items)
    this.addItem(null, null, false, type)
  }

  handleRemoveItem(e) {
    e.preventDefault()
    const index = $(e.currentTarget).data('index')
    const type = $(e.currentTarget).data('type')
    const stars = this.model.get('features')
    const items = this.model.get(type)
    const item = items[index]
    if (item.starPosition >= 0) {
      stars.splice(item.starPosition, 1)
      delete item.starPosition
    }
    items.splice(index, 1)
    this.model.set(type, items)
    this.renderItems(type)
  }

  handleToggleStar(e) {
    e.preventDefault()

    const index = $(e.currentTarget).data('index')
    const type = $(e.currentTarget).data('type')
    const items = this.model.get(type)
    const stars = this.model.get('features')
    const item = items[index]

    if (item.starPosition >= 0) {
      stars.splice(item.starPosition, 1)
      delete item.starPosition
    } else {
      const { value } = item
      const newLength = stars.push(value)
      item.starPosition = newLength - 1
    }

    items[index] = item
    this.model.set(type, items)
    this.model.set('features', stars)
    this.renderStarPoints()

    this.$el.find('.js-star-item').each((index, el) => {
      const $el = $(el)
      $el.siblings()[0].className = $el.prop('checked')
        ? 'icon icon--star--filled'
        : 'icon icon--star'
      if (!$el.prop('checked') && el !== e.currentTarget) {
        $el.attr('disabled', stars.length === 10)
      }
    })
  }

  handleMoveItem(e, top = false) {
    e.preventDefault()

    const type = $(e.currentTarget).data('type')
    const items = this.model.get(type)
    const index = $(e.currentTarget).data('index')
    const item = items[index]
    const label = item.label
    const value = item.value
    const starPosition = item.starPosition

    items.splice(index, 1)

    if (!top) {
      items.push({ label, value, starPosition: starPosition })

      this.model.set(type, items)
    }

    this.renderItems(type)

    this.addItem(label, value, starPosition >= 0, type, top)

    this.renderItems(type)
  }

  handleClearItems(e) {
    e.preventDefault()
    const detailsType = $(e.currentTarget).data('type')
    this.clearItems(detailsType)
  }

  clearItems(type) {
    this.model.set(type, [])
    this.renderItems(type)
  }
}

module.exports = AdditionalDetailsView
