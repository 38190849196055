const CrudService = require('../../../../admin/source/js/lib/crud-service')

class DealService extends CrudService {
  get name() {
    return 'ValuationService'
  }
  get urlRoot() {
    return '/api/valuation'
  }
}

module.exports = DealService
