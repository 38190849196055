const required = require('validity-required')
const schemata = require('schemata')
const isEmail = require('validity-email')
const duplicateEmailValidator = require('../administrator/lib/duplicate-email-validator')
const generateSalt = require('../administrator/lib/salt-generator')
const createPermissions = require('../permission/schema')
const miniReservation = require('../reservation/mini-reservation-schema')

module.exports = save =>
  schemata({
    name: 'Person',
    properties: {
      _id: {
        type: String,
        tag: ['update']
      },
      customerId: {
        type: String
      },
      title: {
        type: String,
        tag: ['update']
      },
      firstName: {
        type: String,
        tag: ['update'],
        validators: {
          all: [required]
        }
      },
      lastName: {
        type: String,
        tag: ['update'],
        validators: {
          all: [required]
        }
      },
      emailAddress: {
        type: String,
        tag: ['update', 'magicLink'],
        validators: {
          all: [required, isEmail, duplicateEmailValidator(save)]
        }
      },
      phoneNumber: {
        type: String,
        tag: ['update']
      },
      favourites: {
        type: Array,
        tag: ['update']
      },
      reservations: {
        type: schemata.Array(miniReservation())
      },
      searches: {
        type: Array,
        tag: ['update']
      },
      password: {
        type: String,
        tag: ['update']
      },
      passwordSalt: {
        type: String,
        defaultValue: generateSalt
      },
      passwordSet: {
        type: Boolean,
        defaultValue: false,
        resolveType: Boolean,
        resolve: ({ password }) => !!password
      },
      permissions: {
        type: createPermissions(['marketing'], ['email'])
      },
      createdDate: {
        type: Date,
        defaultValue: () => new Date()
      },
      magicLink: {
        type: String,
        tag: ['magicLink']
      }
    }
  })
