module.exports = DealView
const View = require('../../style-guide/views/index')
// const dealFormatter = require('../lib/deal-formatter')
const widgetExpander = require('../../widget/lib/widget-expander-toggle')(
  () => {}
)

function DealView(serviceLocator, data, name) {
  this.data = data
  this.name = name

  View.call(this, serviceLocator)
}

DealView.prototype = Object.create(View.prototype)
const join = require('path').join

DealView.prototype.template = function({ data = {} }) {
  const compileJade = require('browjadify-compile')
  const template = {
    deal: function template(locals) {
var buf = [];
var jade_mixins = {};
var jade_interp;
;var locals_for_with = (locals || {});(function (JSON, data, undefined) {






jade_mixins["expander-list"] = jade_interp = function(title, expanded){
var block = (this && this.block), attributes = (this && this.attributes) || {};
buf.push("<div" + (jade.cls(['expander','expander-list','js-expander',expanded ? 'expander-expanded': 'expander-collapsed'], [null,null,null,true])) + "><h3 class=\"expander-list__heading\"><span class=\"expander-header js-expander-toggle\">" + (jade.escape(null == (jade_interp = title) ? "" : jade_interp)) + "</span></h3><div class=\"expander-content\">");
block && block();
buf.push("</div></div>");
};
jade_mixins["detail-list"] = jade_interp = function(items){
var block = (this && this.block), attributes = (this && this.attributes) || {};
buf.push("<dl class=\"detail-list\">");
// iterate items
;(function(){
  var $$obj = items;
  if ('number' == typeof $$obj.length) {

    for (var key = 0, $$l = $$obj.length; key < $$l; key++) {
      var value = $$obj[key];

buf.push("<!-- value starts with / so it's a link--><div" + (jade.cls([value === '-' && 'inactive'], [true])) + ">");
if ( value && value[0] === '/')
{
buf.push("<dt>" + (jade.escape(null == (jade_interp = key) ? "" : jade_interp)) + "</dt><dd><a" + (jade.attr("href", value, true, false)) + ">Click to view</a></dd>");
}
else
{
buf.push("<dt>" + (jade.escape(null == (jade_interp = key) ? "" : jade_interp)) + "</dt><dd>" + (jade.escape(null == (jade_interp = value) ? "" : jade_interp)) + "</dd>");
}
buf.push("</div>");
    }

  } else {
    var $$l = 0;
    for (var key in $$obj) {
      $$l++;      var value = $$obj[key];

buf.push("<!-- value starts with / so it's a link--><div" + (jade.cls([value === '-' && 'inactive'], [true])) + ">");
if ( value && value[0] === '/')
{
buf.push("<dt>" + (jade.escape(null == (jade_interp = key) ? "" : jade_interp)) + "</dt><dd><a" + (jade.attr("href", value, true, false)) + ">Click to view</a></dd>");
}
else
{
buf.push("<dt>" + (jade.escape(null == (jade_interp = key) ? "" : jade_interp)) + "</dt><dd>" + (jade.escape(null == (jade_interp = value) ? "" : jade_interp)) + "</dd>");
}
buf.push("</div>");
    }

  }
}).call(this);

buf.push("</dl>");
};













jade_mixins["section-header"] = jade_interp = function(title, id, data){
var block = (this && this.block), attributes = (this && this.attributes) || {};
buf.push("<div class=\"expander js-expander expander-collapsed\"><div class=\"section-header\"><h2" + (jade.attr("id", id, true, false)) + ">" + (jade.escape(null == (jade_interp = title) ? "" : jade_interp)) + "</h2>");
if ( data)
{
buf.push("<button class=\"expander-header js-expander-toggle\"><span class=\"vhidden\">Toggle raw data</span></button>");
}
buf.push("</div>");
if ( data)
{
buf.push("<div class=\"expander-content\"><pre><code class=\"js-html-editor\">" + (jade.escape(null == (jade_interp = JSON.stringify(data, '', 2)) ? "" : jade_interp)) + "</code></pre></div>");
}
buf.push("</div>");
};
buf.push("<div class=\"page-content\"><div class=\"centering\"><div class=\"sidebar-content\"><div class=\"sidebar-content__sidebar\"><nav class=\"side-nav js-scroll-nav\"><ul class=\"list--unstyled\"><li><a href=\"#overview\">Overview</a></li><li><a href=\"#user-information\">User Information</a></li><li><a href=\"#vehicle\">Vehicle</a></li><li><a href=\"#details\">Deal Details<ul class=\"list--unstyled\"><li><a href=\"#details-tradein\">Trade-in Vehicles <span>(" + (jade.escape((jade_interp = data.tradeInVehicles.length) == null ? '' : jade_interp)) + ")</span></a></li><li><a href=\"#details-extras\">Optional Extras <span>(" + (jade.escape((jade_interp = data.optionalExtras.length) == null ? '' : jade_interp)) + ")</span></a></li></ul></a></li><li><a href=\"#delivery\">Delivery</a></li><li><a href=\"#finance\">Finance<ul class=\"list--unstyled\"></ul></a></li></ul></nav></div><div class=\"sidebar-content__content\"><div class=\"stack stack--small notifications-area\"></div><div id=\"overview\" class=\"section\"><div class=\"section-header\"><div><h1>Deal ID: " + (jade.escape((jade_interp = data.dealId) == null ? '' : jade_interp)) + " (" + (jade.escape((jade_interp = data.state.toUpperCase()) == null ? '' : jade_interp)) + ")</h1><p class=\"h2\">" + (jade.escape((jade_interp = data.displayDate) == null ? '' : jade_interp)) + "</p></div><div class=\"registration-plate\"><span>" + (jade.escape(null == (jade_interp = data.vehicle.Registration) ? "" : jade_interp)) + "</span></div></div></div><section class=\"section\">");
jade_mixins["section-header"]('User Information', 'user-information');
jade_mixins["detail-list"](data.userInfo);
buf.push("</section><section class=\"section\">");
jade_mixins["section-header"]('Vehicle', 'vehicle');
jade_mixins["detail-list"](data.vehicle);
buf.push("</section><section class=\"section\">");
jade_mixins["section-header"]('Details', 'details');
buf.push("<div class=\"stack\"><div id=\"details-tradein\">");
jade_mixins["expander-list"].call({
block: function(){
if ( data.tradeInVehicles.length > 0)
{
buf.push("<ul class=\"list--unstyled\">");
// iterate data.tradeInVehicles
;(function(){
  var $$obj = data.tradeInVehicles;
  if ('number' == typeof $$obj.length) {

    for (var $index = 0, $$l = $$obj.length; $index < $$l; $index++) {
      var item = $$obj[$index];

buf.push("<li class=\"feature\"><div><div><strong>" + (jade.escape((jade_interp = item.vehicleDescription) == null ? '' : jade_interp)) + " - " + (jade.escape((jade_interp = item.registration) == null ? '' : jade_interp)) + "</strong></div><div class=\"footnote\"><a" + (jade.attr("href", item.url, true, false)) + ">View details</a></div></div><div class=\"feature__price\">");
if ( !item.priceVisible)
{
buf.push("<div><span class=\"label label--warning\">Price not visible to User</span></div>");
}
buf.push("<div><div>Valuation: " + (jade.escape((jade_interp = item.valuation) == null ? '' : jade_interp)) + "</div></div></div></li>");
    }

  } else {
    var $$l = 0;
    for (var $index in $$obj) {
      $$l++;      var item = $$obj[$index];

buf.push("<li class=\"feature\"><div><div><strong>" + (jade.escape((jade_interp = item.vehicleDescription) == null ? '' : jade_interp)) + " - " + (jade.escape((jade_interp = item.registration) == null ? '' : jade_interp)) + "</strong></div><div class=\"footnote\"><a" + (jade.attr("href", item.url, true, false)) + ">View details</a></div></div><div class=\"feature__price\">");
if ( !item.priceVisible)
{
buf.push("<div><span class=\"label label--warning\">Price not visible to User</span></div>");
}
buf.push("<div><div>Valuation: " + (jade.escape((jade_interp = item.valuation) == null ? '' : jade_interp)) + "</div></div></div></li>");
    }

  }
}).call(this);

buf.push("</ul>");
}
else
{
buf.push("<p>No items in this category</p>");
}
}
}, `Trade-in Vehicles (${data.tradeInVehicles.length})`, data.tradeInVehicles.length > 0);
buf.push("</div><div id=\"details-extras\">");
jade_mixins["expander-list"].call({
block: function(){
if ( data.optionalExtras.length > 0)
{
buf.push("<ul class=\"list--unstyled\">");
// iterate data.optionalExtras
;(function(){
  var $$obj = data.optionalExtras;
  if ('number' == typeof $$obj.length) {

    for (var $index = 0, $$l = $$obj.length; $index < $$l; $index++) {
      var item = $$obj[$index];

buf.push("<li class=\"feature\"><div><div><strong>" + (jade.escape(null == (jade_interp = item.title) ? "" : jade_interp)) + "</strong></div><div class=\"footnote\">" + (jade.escape(null == (jade_interp = item.description) ? "" : jade_interp)) + "</div></div><div class=\"feature__price\"><div>Price: " + (jade.escape((jade_interp = item.price) == null ? '' : jade_interp)) + "</div>");
if ( item.originalPrice > 0)
{
buf.push("<div class=\"footnote\">Original Price: " + (jade.escape((jade_interp = item.originalPrice) == null ? '' : jade_interp)) + "</div>");
}
buf.push("</div></li>");
    }

  } else {
    var $$l = 0;
    for (var $index in $$obj) {
      $$l++;      var item = $$obj[$index];

buf.push("<li class=\"feature\"><div><div><strong>" + (jade.escape(null == (jade_interp = item.title) ? "" : jade_interp)) + "</strong></div><div class=\"footnote\">" + (jade.escape(null == (jade_interp = item.description) ? "" : jade_interp)) + "</div></div><div class=\"feature__price\"><div>Price: " + (jade.escape((jade_interp = item.price) == null ? '' : jade_interp)) + "</div>");
if ( item.originalPrice > 0)
{
buf.push("<div class=\"footnote\">Original Price: " + (jade.escape((jade_interp = item.originalPrice) == null ? '' : jade_interp)) + "</div>");
}
buf.push("</div></li>");
    }

  }
}).call(this);

buf.push("</ul>");
}
else
{
buf.push("<p>No items in this category</p>");
}
}
}, `Optional Extras (${data.optionalExtras.length})`, data.optionalExtras.length > 0);
buf.push("</div></div></section><section class=\"section\">");
jade_mixins["section-header"]('Delivery', 'delivery');
if ( data.deliveryType === 'delivery' && data.deliveryAddress.length)
{
buf.push("<div><strong>Delivery Date:</strong></div><p>" + (jade.escape(null == (jade_interp = data.deliveryDate) ? "" : jade_interp)) + "</p><div><strong>Delivery Address:</strong></div><div><address>");
// iterate data.deliveryAddress
;(function(){
  var $$obj = data.deliveryAddress;
  if ('number' == typeof $$obj.length) {

    for (var $index = 0, $$l = $$obj.length; $index < $$l; $index++) {
      var line = $$obj[$index];

buf.push("<div>" + (jade.escape(null == (jade_interp = line) ? "" : jade_interp)) + "</div>");
    }

  } else {
    var $$l = 0;
    for (var $index in $$obj) {
      $$l++;      var line = $$obj[$index];

buf.push("<div>" + (jade.escape(null == (jade_interp = line) ? "" : jade_interp)) + "</div>");
    }

  }
}).call(this);

buf.push("</address></div>");
}
else if ( data.deliveryType === 'collection' && data.collectionLocation)
{
buf.push("<div><strong>Collection Date:</strong></div><p>" + (jade.escape(null == (jade_interp = data.deliveryDate) ? "" : jade_interp)) + "</p><div><strong>Collection Location:</strong></div><div>" + (jade.escape(null == (jade_interp = data.collectionLocation.location) ? "" : jade_interp)) + "</div>");
}
else
{
buf.push("<p>Delivery Type not defined</p>");
}
buf.push("</section><section class=\"section\">");
jade_mixins["section-header"]('Finance', 'finance');
jade_mixins["detail-list"](data.finance);
buf.push("</section></div></div></div></div>");}.call(this,"JSON" in locals_for_with?locals_for_with.JSON:typeof JSON!=="undefined"?JSON:undefined,"data" in locals_for_with?locals_for_with.data:typeof data!=="undefined"?data:undefined,"undefined" in locals_for_with?locals_for_with.undefined:typeof undefined!=="undefined"?undefined:undefined));;return buf.join("");
},
    finance: function template(locals) {
var buf = [];
var jade_mixins = {};
var jade_interp;
;var locals_for_with = (locals || {});(function (JSON, data) {












jade_mixins["detail-list"] = jade_interp = function(items){
var block = (this && this.block), attributes = (this && this.attributes) || {};
buf.push("<dl class=\"detail-list\">");
// iterate items
;(function(){
  var $$obj = items;
  if ('number' == typeof $$obj.length) {

    for (var key = 0, $$l = $$obj.length; key < $$l; key++) {
      var value = $$obj[key];

buf.push("<!-- value starts with / so it's a link--><div" + (jade.cls([value === '-' && 'inactive'], [true])) + ">");
if ( value && value[0] === '/')
{
buf.push("<dt>" + (jade.escape(null == (jade_interp = key) ? "" : jade_interp)) + "</dt><dd><a" + (jade.attr("href", value, true, false)) + ">Click to view</a></dd>");
}
else
{
buf.push("<dt>" + (jade.escape(null == (jade_interp = key) ? "" : jade_interp)) + "</dt><dd>" + (jade.escape(null == (jade_interp = value) ? "" : jade_interp)) + "</dd>");
}
buf.push("</div>");
    }

  } else {
    var $$l = 0;
    for (var key in $$obj) {
      $$l++;      var value = $$obj[key];

buf.push("<!-- value starts with / so it's a link--><div" + (jade.cls([value === '-' && 'inactive'], [true])) + ">");
if ( value && value[0] === '/')
{
buf.push("<dt>" + (jade.escape(null == (jade_interp = key) ? "" : jade_interp)) + "</dt><dd><a" + (jade.attr("href", value, true, false)) + ">Click to view</a></dd>");
}
else
{
buf.push("<dt>" + (jade.escape(null == (jade_interp = key) ? "" : jade_interp)) + "</dt><dd>" + (jade.escape(null == (jade_interp = value) ? "" : jade_interp)) + "</dd>");
}
buf.push("</div>");
    }

  }
}).call(this);

buf.push("</dl>");
};













jade_mixins["section-header"] = jade_interp = function(title, id, data){
var block = (this && this.block), attributes = (this && this.attributes) || {};
buf.push("<div class=\"expander js-expander expander-collapsed\"><div class=\"section-header\"><h2" + (jade.attr("id", id, true, false)) + ">" + (jade.escape(null == (jade_interp = title) ? "" : jade_interp)) + "</h2>");
if ( data)
{
buf.push("<button class=\"expander-header js-expander-toggle\"><span class=\"vhidden\">Toggle raw data</span></button>");
}
buf.push("</div>");
if ( data)
{
buf.push("<div class=\"expander-content\"><pre><code class=\"js-html-editor\">" + (jade.escape(null == (jade_interp = JSON.stringify(data, '', 2)) ? "" : jade_interp)) + "</code></pre></div>");
}
buf.push("</div>");
};
buf.push("<div class=\"page-content\"><div class=\"centering\"><div class=\"sidebar-content\"><div class=\"sidebar-content__sidebar\"><nav class=\"side-nav js-scroll-nav\"><ul class=\"list--unstyled\"><li><a href=\"#overview\">Overview</a></li><li><a href=\"#user-information\">User Information</a></li><li><a href=\"#vehicle\">Vehicle</a></li><li><a href=\"#quote\">Quote<ul class=\"list--unstyled\"></ul></a></li></ul></nav></div><div class=\"sidebar-content__content\"><div class=\"stack stack--small notifications-area\"></div><div id=\"overview\" class=\"section\"><div class=\"section-header\"><div><h1>Number " + (jade.escape((jade_interp = data.applicationId) == null ? '' : jade_interp)) + " (" + (jade.escape((jade_interp = data.state.toUpperCase()) == null ? '' : jade_interp)) + ")</h1><p class=\"h2\">" + (jade.escape((jade_interp = data.displayDate) == null ? '' : jade_interp)) + "</p><p class=\"h2\">Financing for Deal " + (jade.escape((jade_interp = data.dealId) == null ? '' : jade_interp)) + "</p></div><div class=\"registration-plate\"><span>" + (jade.escape(null == (jade_interp = data.vehicle.Registration) ? "" : jade_interp)) + "</span></div></div></div><section class=\"section\">");
jade_mixins["section-header"]('User Information', 'user-information');
jade_mixins["detail-list"](data.userInfo);
buf.push("</section><section class=\"section\">");
jade_mixins["section-header"]('Vehicle', 'vehicle');
jade_mixins["detail-list"](data.vehicle);
buf.push("</section><section class=\"section\">");
jade_mixins["section-header"]('Quote', 'quote');
buf.push("<p><strong>" + (jade.escape(null == (jade_interp = data.financeName) ? "" : jade_interp)) + "</strong></p>");
jade_mixins["detail-list"](data.quote);
jade_mixins["detail-list"](data.fees);
buf.push("</section></div></div></div></div>");}.call(this,"JSON" in locals_for_with?locals_for_with.JSON:typeof JSON!=="undefined"?JSON:undefined,"data" in locals_for_with?locals_for_with.data:typeof data!=="undefined"?data:undefined));;return buf.join("");
}
  }

  // return template({ data: dealFormatter(data) })
  return template[this.name]({ data: data })
}

DealView.prototype.render = function() {
  this.$el
    .empty()
    .append(this.template({ data: this.data, config: window.config }))

  this.renderTooltips()
  this.renderForegroundNotifications()
  this.renderModals()
  this.handleScrollNav()
  this.handleExpanders()

  // If a hash is present in the URL, scroll to its on-page position
  if (window.location.hash) {
    var hashElement = this.$el.find(window.location.hash)
    process.nextTick(function() {
      const hashElementOffset = hashElement.length
        ? $(hashElement).offset().top
        : 0
      const bodyOffset = 68 // Account for the height of the toolbar, plus a little more

      if (hashElementOffset > 0) {
        $('body').scrollTop(hashElementOffset - bodyOffset)
      }
    })
  }

  return this
}

DealView.prototype.handleScrollNav = function() {
  var scrollNavLinks = [...this.$el.find('.js-scroll-nav a')]
  var yOffset = -60

  scrollNavLinks.forEach(function(link) {
    link.addEventListener('click', function(e) {
      e.preventDefault()
      const element = document.getElementById(
        e.target.getAttribute('href').replace('#', '')
      )
      const y =
        element.getBoundingClientRect().top + window.pageYOffset + yOffset

      if (!y) return
      window.scrollTo({ top: y, behavior: 'smooth' })

      if (history) {
        history.replaceState(null, '', e.target.getAttribute('href'))
      }
    })
  })
}

DealView.prototype.handleExpanders = function() {
  this.$el.on(
    'click',
    '.js-expander-toggle',
    widgetExpander.toggleExpander.bind(this)
  )
}
