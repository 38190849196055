const schemata = require('schemata')
const customerWifiLoginsSchema = require('./customer-wifi-schema')

module.exports = () => {
  const item = schemata({
    name: 'link',
    properties: {
      link: { type: String },
      location: { type: String }
    }
  })

  const schema = schemata({
    name: 'Customer',
    properties: {
      _id: { type: String },
      account: { type: String },
      title: { type: String },
      firstName: {
        type: String
      },
      lastName: {
        type: String
      },
      emailAddress: {
        type: String
      },
      phoneNumber: {
        type: String
      },
      tags: {
        type: Array
      },
      locations: {
        type: Array
      },
      reviewLinks: {
        type: schemata.Array(item)
      },
      customerWifiLogins: {
        type: schemata.Array(customerWifiLoginsSchema)
      },
      type: {
        type: String
      },
      adminId: {
        type: String
      },
      createdDate: {
        type: Date,
        defaultValue: () => new Date()
      }
    }
  })

  return schema
}
