const ValuationService = require('./services/valuation')

const initAdmin = () => ({ valuationAdmin: [init] })

const init = (serviceLocator, cb) => {
  serviceLocator.valuationService = new ValuationService(serviceLocator)
  cb(null)
}

module.exports = initAdmin
