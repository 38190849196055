const schemata = require('schemata')
const createDealershipSchema = require('../dealership/schema')
const featureSetSchema = require('../features/feature-set-schema')()
const createUniqueValidator = require('validity-unique-property')
const validateIfSet = require('validity-validate-if-set')
const required = require('validity-required')
const sizeValidator = require('validity-length')
const makeSchema = require('../make/schema')
const extrasSchema = require('../deal/schemas/extras')
const customValidityMessage = require('../../../lib/validators/custom-validator-message')
const validateIf = require('../../../lib/validate-if')
const fileSchema = require('../s3/file-schema')
const deriveAutoTraderUrl = require('./lib/derive-autotrader-url')
const videoPlatforms = require('../../common/video/platforms')
const createRangeValidator = require('validity-number-in-range')
const resolveImages = require('../../../lib/image-resolver')
const requireOne = require('validity-require-one')
const validateIfPropertySet = require('validity-validate-if-property-set')
const hat = require('hat')

const validateDealershipVwfsId = readDealership => async (
  prop,
  name,
  object,
  callback
) => {
  const dealership = await readDealership(object.dealership)
  if (!dealership) {
    return callback(null, 'Vehicle missing dealership')
  }
  if (!dealership.vwfsFinanceId) {
    return callback(
      null,
      `Vehicle dealership (${dealership.shortName}) missing VWFS ID`
    )
  }
  return callback(null)
}

const videoSchema = () =>
  schemata({
    name: 'Video Schema',
    properties: {
      upToDate: {
        type: Boolean,
        defaultValue: () => false
      },
      priority: {
        type: Number,
        defaultValue: () => 0
      },
      id: {
        type: String
      },
      provider: {
        type: String,
        default: 'youtube'
      }
    }
  })

const financeSchema = () =>
  schemata({
    name: 'Finance Schema',
    properties: {
      externalId: {
        type: String
      },
      monthlyPayment: {
        type: Number
      },
      carPrice: {
        type: Number
      },
      depositAmount: {
        type: Number
      },
      maxDepositAmount: {
        type: Number
      },
      adminFee: {
        type: Number
      },
      balanceToFinance: {
        type: Number
      },
      firstPayment: {
        type: Number
      },
      numberOfPayments: {
        type: Number
      },
      finalPayment: {
        type: Number
      },
      amountPayable: {
        type: Number
      },
      interestCharges: {
        type: Number
      },
      interestApr: {
        type: Number
      },
      interestRate: {
        type: Number
      },
      optionFee: {
        type: Number
      },
      lender: {
        type: String
      },
      financeCalculatorError: {
        type: String,
        resolve: () => null
      }
    }
  })

const taxSchema = () =>
  schemata({
    name: 'Vehicle Tax Info',
    properties: {
      price: {
        type: Number
      },
      category: {
        type: String
      },
      band: {
        type: String
      }
    }
  })

module.exports = ({
  usedVehicleFind,
  readDealership,
  readColour,
  readMake
} = {}) => {
  const uniqueValidator = customValidityMessage(
    createUniqueValidator(usedVehicleFind),
    'This {name} ({value}) is already in use'
  )

  const colourToString = async colourId => {
    const colour = await readColour(colourId)

    return (colour && colour.name) || ''
  }

  const imageValidator = (prop, name, object) => {
    const image = object[prop]

    // some vehicles from autoloadit don't have images
    if (object.dataOrigin === 'autoloadit') {
      return
    }

    // don't attempt to validate an autotrader vehicle image
    // before importing the images
    if (object.dataOrigin === 'autoTrader') {
      return
    }

    // new vehicles don't need images
    if (object.saleType === 'new') {
      return
    }

    // if it's a KIA car, it might be getting images from KIA Bluesky
    if (object.brand.toLowerCase() === 'kia') {
      return
    }

    if (!image || !image.widgets || !image.widgets.length) {
      return 'This vehicle must have a primary image set'
    }
  }

  const isModix = brand =>
    !!brand &&
    ['volkswagen', 'seat', 'cupra', 'skoda'].includes(brand.toLowerCase())

  const validateIfModix = validator => validateIf(validator, isModix, 'brand')
  const requiredIfModix = validateIfModix(required)

  const requiredIfVan = validateIf(
    required,
    category => !!category && category.toLowerCase() === 'van',
    'category'
  )

  const requiredIfNew = validateIf(
    required,
    saleType => saleType === 'new',
    'saleType'
  )

  const notNone = (prop, name, object) => {
    if (object[prop] === 'none') {
      return `This vehicle should have a valid ${name} set`
    }
  }

  const yearValidator = (prop, name, object) => {
    if (object[prop] && !Number.isInteger(object[prop])) {
      return `This should only contain the year e.g. 2018 not 2018.5`
    }
  }

  // if not a new vehicle, require registration or vin
  const requireRegOrVin = validateIf(
    requireOne(['registration', 'vin']),
    saleType => saleType !== 'new',
    'saleType'
  )

  const validateIfNotAutoTrader = fn =>
    validateIf(fn, dataOrigin => dataOrigin !== 'autoTrader', 'dataOrigin')

  const validateRequiredIfGoingToModix = () =>
    function validate(key, keyDisplayName, obj, cb) {
      if (obj.dataOrigin !== 'autoloadit' && obj.saleType !== 'new') {
        return requiredIfModix(key, keyDisplayName, obj, cb)
      }

      return cb(null, undefined)
    }

  const co2Validator = (prop, name, object) => {
    if (
      isModix(object.brand) &&
      object.fuel !== 'Electric' &&
      (!Number.isInteger(object[prop]) || object[prop] < 1)
    ) {
      return 'This vehicle must have valid CO2 emissions'
    }
  }

  const schema = schemata({
    name: 'UsedVehicle',
    properties: {
      _id: {
        type: String,
        tag: ['service']
      },
      registration: {
        type: String,
        validators: {
          service: [
            validateIfSet(uniqueValidator),
            validateIfNotAutoTrader(requireRegOrVin)
          ],
          draft: [
            validateIfSet(uniqueValidator),
            validateIfNotAutoTrader(requireRegOrVin)
          ],
          published: [
            validateIfSet(uniqueValidator),
            validateIfNotAutoTrader(requireRegOrVin)
          ],
          archived: [
            validateIfSet(uniqueValidator),
            validateIfNotAutoTrader(requireRegOrVin)
          ]
        }
      },
      brand: {
        type: String,
        validators: { draft: [required], published: [required] }
      },
      model: {
        type: String,
        validators: { draft: [required], published: [required] }
      },
      derivative: {
        type: String,
        validators: {
          draft: [
            validateIf(required, item => item !== 'autoloadit', 'dataOrigin')
          ],
          published: [
            validateIf(required, item => item !== 'autoloadit', 'dataOrigin')
          ]
        }
      },
      registrationDate: {
        type: Date,
        validators: {
          published: [validateIfPropertySet('registration', required)]
        }
      },
      year: {
        type: Number,
        validators: { published: [required, yearValidator] }
      },
      colour: {
        type: String,
        resolveType: String,
        resolve: vehicle => readColour && colourToString(vehicle.baseColour)
      },
      mileage: {
        type: Number,
        validators: { published: [required] }
      },
      value: {
        type: Number,
        defaultValue: 0,
        validators: { published: [required, createRangeValidator(1)] }
      },
      previewId: {
        type: String,
        defaultValue: () => hat(32, 16)
      },
      reservable: {
        type: Boolean,
        defaultValue: true
      },
      reservePrice: {
        type: Number,
        defaultValue: 500
      },
      engineSize: {
        type: Number
      },
      transmission: {
        type: String,
        validators: { published: [required] }
      },
      transmissionStyle: {
        type: String,
        validators: { published: [required] }
      },
      fuel: {
        type: String,
        validators: { published: [required] }
      },
      category: {
        type: String,
        validators: { published: [required] }
      },
      dealership: {
        type: String,
        resolve: usedVehicle =>
          readDealership && readDealership(usedVehicle.dealership),
        resolveType: readDealership && createDealershipSchema(),
        defaultValue: 'none',
        validators: {
          draft: [required],
          published: [required, notNone]
        }
      },
      image: {
        type: Object,
        defaultValue: () => {},
        resolve: vehicle => {
          const [image] = resolveImages(vehicle.image)
          return image
        },
        resolveType: String,
        validators: { published: [imageValidator] }
      },
      images: {
        type: Object,
        defaultValue: () => ({ widgets: [] }),
        resolve: async vehicle => {
          const images = resolveImages(vehicle.images)
          const dealership =
            readDealership && (await readDealership(vehicle.dealership))

          if (
            dealership &&
            dealership.marketingImages &&
            Array.isArray(dealership.marketingImages)
          ) {
            images.push(...resolveImages(dealership.marketingImages))
          }

          return images
        },
        tag: ['service'],
        resolveType: Array
      },
      // don't expose this via graphql
      vinImages: {
        type: Object,
        defaultValue: () => {},
        resolve: () => [],
        resolveType: Array
      },
      videoImages: {
        type: Array,
        resolveType: schemata.Array(
          schemata({
            name: 'VideoImages',
            properties: {
              src: {
                type: String
              },
              name: {
                type: String
              }
            }
          })
        ),
        resolve: async vehicle => {
          if (!vehicle.images || !vehicle.images.widgets) {
            return []
          }
          const { widgets } = vehicle.images
          return widgets
            .map(image => {
              const crop = image.crops && image.crops[0]

              if (!crop) {
                return
              }
              return { src: crop.src, name: image.name }
            })
            .filter(Boolean)
        }
      },
      aiFeatures: {
        type: Object,
        resolveType: schemata({
          name: 'VehicleVideoAiFeatures',
          properties: {
            exteriorHeadlight: { type: String },
            steeringWheel: { type: String },
            interior: { type: Array },
            wheel: { type: String },
            steeringWheelControls: { type: String },
            centerConsoleInfotainment: { type: String }
          }
        }),
        resolve: async vehicle => {
          return vehicle.aiFeatures
        }
      },
      featured: {
        type: Boolean
      },
      bodyStyle: {
        type: String
      },
      seats: {
        type: Number
      },
      mpg: {
        type: Number
      },
      co2: {
        type: Number,
        validators: {
          published: [co2Validator]
        }
      },
      hpFinance: {
        type: financeSchema('HP')
      },
      pcpFinance: {
        type: financeSchema('PCP')
      },
      bhp: {
        type: Number,
        validators: { published: [required] }
      },
      interior: {
        type: schemata.Array(featureSetSchema)
      },
      exterior: {
        type: schemata.Array(featureSetSchema)
      },
      features: {
        type: Array
      },
      technical: {
        type: schemata.Array(featureSetSchema)
      },
      safety: {
        type: schemata.Array(featureSetSchema)
      },
      exteriorImages: {
        type: Object,
        defaultValue: () => [],
        tag: ['service'],
        resolve: model => resolveImages({ widgets: model.exteriorImages }),
        resolveType: Array
      },
      interiorImages: {
        type: Object,
        defaultValue: () => ({ widgets: [] }),
        tag: ['service'],
        resolve: model => resolveImages(model.interiorImages),
        resolveType: Array
      },
      expiredDate: {
        type: Date
      },
      reservedDate: {
        type: Date
      },
      createdDate: {
        type: Date,
        defaultValue: () => new Date()
      },
      lastModifiedDate: {
        type: Date,
        defaultValue: () => new Date()
      },
      autoLoadItBranchId: {
        type: Number
      },
      vatCategory: {
        type: String,
        validators: { published: [required] }
      },
      vin: {
        type: String,
        validators: {
          draft: [
            validateIfSet(uniqueValidator),
            validateIfNotAutoTrader(requireRegOrVin)
          ],
          published: [
            validateIfSet(uniqueValidator),
            validateIfNotAutoTrader(requireRegOrVin)
          ],
          archived: [
            validateIfSet(uniqueValidator),
            validateIfNotAutoTrader(requireRegOrVin)
          ]
        }
      },
      dataOrigin: {
        type: String,
        // this does nothing, but I like it to describe intent
        options: ['autoloadit', 'carweb', 'duplicate', 'autoTrader', 'blank'],
        defaultValue: 'blank'
      },
      doors: {
        type: Number,
        resolveType: Number,
        resolve: vehicle => {
          if (!vehicle.doors || Number.isNaN(vehicle.doors)) {
            return null
          }

          return vehicle.doors
        }
      },
      gears: {
        type: Number
      },
      specOptions: {
        type: Array,
        resolveType: String,
        resolve: () => ''
      },
      account: {
        type: String
      },
      state: {
        type: String,
        options: ['Draft', 'Published', 'Archived'],
        defaultValue: 'Draft',
        validators: { all: [] }
      },
      publishToAutoTrader: {
        type: Boolean,
        defaultValue: false
      },
      attentionGrabber: {
        type: String,
        validators: {
          draft: [sizeValidator(0, 30)],
          published: [
            validateIf(required, item => !!item, 'publishToAutoTrader'),
            validateIfNotAutoTrader(sizeValidator(1, 30))
          ]
        }
      },
      autoTraderComment: {
        type: String,
        validators: {
          draft: [sizeValidator(0, 3000)],
          published: [sizeValidator(0, 3000)]
        }
      },
      previousOwners: {
        type: Number
      },
      autoTraderId: {
        type: String
      },
      brandApproved: {
        type: String,
        resolveType: makeSchema(),
        resolve: model => model.brandApproved && readMake(model.brandApproved)
      },
      autoTraderImageCount: {
        type: String
      },
      interiorMaterial: {
        type: String,
        validators: {
          published: [validateRequiredIfGoingToModix()]
        }
      },
      interiorColour: {
        type: String,
        validators: {
          published: [requiredIfModix]
        },
        resolveType: String,
        resolve: vehicle => readColour && colourToString(vehicle.interiorColour)
      },
      dealerApproved: {
        type: Boolean
      },
      demonstration: {
        type: Boolean
      },
      publishedDate: {
        type: Date
      },
      isSold: {
        type: Boolean,
        default: false
      },
      baseColour: {
        type: String,
        validators: { published: [required] },
        resolveType: String,
        resolve: vehicle => readColour && colourToString(vehicle.baseColour)
      },
      manufacturerColour: {
        type: String,
        validators: {
          published: [
            validateIf(required, saleType => saleType !== 'new', 'saleType')
          ]
        },
        resolveType: String,
        resolve: vehicle =>
          readColour && colourToString(vehicle.manufacturerColour)
      },
      carwebEmbellished: {
        type: Boolean
      },
      hasKiaImages: {
        type: Boolean
      },
      video: {
        type: videoSchema(),
        resolveType: String,
        tag: ['service'],
        resolve: vehicle => {
          if (!vehicle.video) return ''

          const { id, provider } = vehicle.video
          const platform = videoPlatforms[provider]

          if (!platform) return ''

          return platform.link(id)
        }
      },
      cdkStockedDate: {
        type: Date
      },
      insuranceGroup: {
        type: String
      },
      taxInfo: {
        type: taxSchema()
      },
      drive: {
        type: String,
        validators: { published: [required] }
      },
      dynavidClips: {
        type: schemata.Array(fileSchema('Dynavid File')),
        defaultValue: () => []
      },
      capId: {
        type: String
      },
      capCode: {
        type: String,
        validators: {
          published: [validateIfNotAutoTrader(requiredIfNew)]
        }
      },
      previousUsage: {
        type: String
      },
      modixRange: {
        type: String,
        validators: {
          published: [validateRequiredIfGoingToModix()]
        }
      },
      autoTraderFinder: {
        type: String,
        resolveType: String,
        resolve: async vehicle => {
          const dealership =
            readDealership && (await readDealership(vehicle.dealership))
          vehicle.colour =
            readColour && (await colourToString(vehicle.baseColour))

          return deriveAutoTraderUrl(dealership, vehicle)
        }
      },
      hasV5: {
        type: Boolean
      },
      condition: {
        type: String
      },
      wltpMinCO2: {
        type: Number
      },
      wltpMaxCO2: {
        type: Number
      },
      autoloaditId: {
        type: String
      },
      customModixRange: {
        type: String,
        validators: {
          published: [
            validateIf(requiredIfModix, item => item === 'custom', 'modixRange')
          ]
        }
      },
      warrantyDate: {
        type: Date
      },
      trim: {
        type: String,
        validators: {
          published: [requiredIfModix]
        }
      },
      wheelBase: {
        type: String,
        validators: {
          published: [requiredIfVan]
        }
      },
      needsIccUpdate: {
        type: Boolean,
        tag: ['service']
      },
      previousAutoTraderStockId: {
        type: String
      },
      autoTraderStockId: {
        type: String
      },
      autoTraderSearchId: {
        type: String
      },
      financeUpToDate: {
        type: Boolean
      },
      autoTraderVehicleType: {
        type: String,
        validators: [requiredIfNew]
      },
      autoTraderMake: {
        type: String,
        validators: [requiredIfNew]
      },
      autoTraderModel: {
        type: String,
        validators: [requiredIfNew]
      },
      autoTraderGeneration: {
        type: String,
        validators: [requiredIfNew]
      },
      autoTraderDerivative: {
        type: String,
        validators: [requiredIfNew]
      },
      soldDate: {
        type: Date
      },
      needsImageCacheClear: {
        type: Boolean
      },
      needsImageProcessing: {
        type: Boolean
      },
      canPurchase: {
        type: Boolean,
        validators: {
          published: [
            readDealership &&
              validateIfSet(validateDealershipVwfsId(readDealership))
          ]
        }
      },
      matPrice: {
        type: Number
      },
      purchaseExtras: {
        type: schemata.Array(extrasSchema()),
        defaultValue: () => [],
        resolveType: schemata.Array(extrasSchema()),
        resolve: vehicle => {
          const extras = vehicle.purchaseExtras || []

          if (vehicle.matPrice) {
            extras.push({
              _id: 'bespokeMats',
              title: 'Bespoke Mats',
              description: `Every ${vehicle.brand} driver is unique,
                which is why we have designed a wide range of additional
                mats to make your ${vehicle.brand} truly yours`,
              price: vehicle.matPrice
            })
          }

          extras.push({
            _id: 'extraFuel',
            title: '£50 fuel in the tank',
            description:
              'For your convenience, we will add up to £50 fuel to your tank',
            price: 50
          })

          return extras
        }
      },
      saleType: {
        type: String,
        defaultValue: () => 'used'
      },
      manualTriggerAutoTraderDate: {
        type: Date
      },
      manualTriggerCodeweaversDate: {
        type: Date
      },
      modixViewers: {
        type: Number
      },
      onModixDate: {
        type: Date
      },
      analyticsViews: {
        type: Number
      },
      autoTraderClickThroughs: {
        type: Number
      },
      calltracksCalls: {
        type: Number
      },
      hotScore: {
        type: Number
      },
      onSale: {
        type: Boolean,
        defaultValue: false,
        resolve: vehicle => {
          if (vehicle.onSale === true) return 'On Sale'
          return null
        },
        resolveType: String
      },
      checkoutDemoVehicle: {
        type: Boolean,
        defaultValue: false
      },
      autoTraderError: {
        type: String
      }
    }
  })

  return schema
}
