const {
  default: formatFinanceQuote
} = require('../../../service/email-generator/emails/lib/finance-quote-formatter')
const View = require('../views/viewer')
const debug = require('../../../../admin/source/js/lib/debug')('customer deal')

const formatCurrency = val =>
  `${new Intl.NumberFormat('en-GB', {
    style: 'currency',
    currency: 'GBP'
  }).format(val)}`.replace('.00', '')

const formatDate = (date, showTime) =>
  new Intl.DateTimeFormat('en-GB', {
    dateStyle: 'long',
    timeStyle: showTime ? 'medium' : undefined
  }).format(new Date(date))

module.exports = serviceLocator => {
  const router = serviceLocator.router

  router.route('customer/finance/:id', 'viewFinanceData', async id => {
    if (!serviceLocator.allow('customer', 'read')) return false
    debug('rendering customer deal view', id)

    const data = await new Promise((resolve, reject) => {
      serviceLocator.vehicleFinanceService.read(id, (err, data) => {
        if (err) {
          serviceLocator.router.trigger(
            'notFound',
            'There was an issue retrieving this deal'
          )
          return reject(err)
        }
        resolve(data)
      })
    })

    const dealInfo = await new Promise((resolve, reject) => {
      if (!data.dealId) {
        return resolve()
      }

      serviceLocator.dealService.read(data.dealId, (err, deal) => {
        if (err) {
          return reject(err)
        }
        resolve(deal)
      })
    })

    const vehicle = await new Promise((resolve, reject) => {
      serviceLocator.usedVehicleService.read(
        dealInfo.vehicle,
        (err, vehicle) => {
          if (err) {
            return reject(err)
          }
          resolve(vehicle)
        }
      )
    })

    const {
      financeDetails: { mainApplicant },
      originalQuote
    } = data

    const formattedQuote = formatFinanceQuote(originalQuote)

    const formattedData = {
      // Overview
      displayDate: formatDate(data.createdDate, true),
      dealId: dealInfo.dealId,
      applicationId: data.financeDetails.applicationNumber,
      state: data.financeDetails.status,

      // User Information
      userInfo: {
        Name: `${mainApplicant.title} ${mainApplicant.firstname} ${
          mainApplicant.lastname
        }`,
        Email: mainApplicant.email,
        Phone: mainApplicant.phoneNumber
      },

      // Vehicle
      vehicle: {
        Registration: vehicle.registration.toUpperCase(),
        'Vehicle Type': vehicle.category,
        Make: vehicle.brand,
        Model: vehicle.model,
        Derivative: vehicle.derivative,
        Value: formatCurrency(vehicle.value)
      },

      financeName: formattedQuote.name,
      quote: formattedQuote.infoItems,
      fees: formattedQuote.fees
    }

    const view = new View(serviceLocator, formattedData, 'finance')
    router.render(view)
  })
}
