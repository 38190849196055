const compileJade = require('browjadify-compile')
const join = require('path').join
const template = function template(locals) {
var buf = [];
var jade_mixins = {};
var jade_interp;
;var locals_for_with = (locals || {});(function (link) {
buf.push("<div id=\"section-link\" class=\"panel panel-styled\"><div class=\"panel-header\"><h2>Link</h2></div><div class=\"panel-content\"><div id=\"field--link\" data-field=\"link\" class=\"form-row\"><label><input type=\"text\" name=\"link\"" + (jade.attr("value", link, true, false)) + " autofocus=\"autofocus\" class=\"control control--text form-field\"/></label><div class=\"modal-link-text\"><p>Use this link as described in the following instructions</p></div><div class=\"js-error\"></div></div></div></div><div id=\"section-basic-details\" class=\"panel panel-styled\"><div class=\"expander expander-list js-expander expander-collapsed\"><div class=\"panel-header js-expander-toggle\"><div class=\"expander-list__heading\"><h2>Calendar Instructions</h2><p>These instructions assume you're using the Outlook Web App</p></div></div><div class=\"panel-content expander-content\"><div class=\"modal-ordered-list\"><ol> <li> <span>Sign in to the Outlook Web App</span></li><li> <span>At the bottom of the page, select Calendar.</span></li><li><span>In the navigation pane, select Add calendar.</span></li><li> <span>Select Subscribe from web.</span><div class=\"list-image\"> <img src=\"/assets/img/content/calendar-instructions/calendar.png\" alt=\"Citygate\"/></div></li><li> <span>Enter the URL for the calendar.</span></li><li> <span>Select Import.</span></li></ol></div><h3>What to do if you don't see Add calendar</h3><p>If you've collapsed the navigation pane next to the calendar, you'll have to expand it to reach the Add calendar feature. To expand the navigation pane, select the icon outlined in red to expand or collapse the left pane at the top of the calendar.</p><img src=\"/assets/img/content/calendar-instructions/calendar2.png\" alt=\"Citygate\"/><br/></div></div></div>");}.call(this,"link" in locals_for_with?locals_for_with.link:typeof link!=="undefined"?link:undefined));;return buf.join("");
}
const View = require('ventnor')
const widgetExpander = require('../../widget/lib/widget-expander-toggle')(
  () => {}
)

class CalendarView extends View {
  constructor(options = {}) {
    super(...arguments)
    this.link = options.link
    this.$el.on(
      'click',
      '.js-expander-toggle',
      widgetExpander.toggleExpander.bind(this)
    )
  }

  render() {
    this.$el.empty().append(template({ link: this.link }))
    return this
  }
}

module.exports = CalendarView
