const VehicleFinanceService = require('./services/vehicle-finance')

const initAdmin = () => ({ vehicleFinanceAdmin: [init] })

const init = (serviceLocator, cb) => {
  serviceLocator.vehicleFinanceService = new VehicleFinanceService(
    serviceLocator
  )
  cb(null)
}

module.exports = initAdmin
