const required = require('validity-required')
const schemata = require('schemata')
const createMakeSchema = require('../make/schema')
const isEmail = require('validity-email')
const validateIfSet = require('validity-validate-if-set')
const isUrl = require('validity-url')
const resolveImages = require('../../../lib/image-resolver')

const collectionSchema = schemata({
  name: 'CollectionTime',
  properties: {
    day: { type: Number },
    hours: { type: String },
    canCollect: { type: Boolean }
  }
})

const days = [
  'sunday',
  'monday',
  'tuesday',
  'wednesday',
  'thursday',
  'friday',
  'saturday'
]

const requireValidation = process.env.AUTOWORKS_INSTANCE !== 'genesis'

module.exports = ({
  readMake,
  readReviewEntity,
  defaultDeliveryDistance
} = {}) => {
  const schema = schemata({
    name: 'Dealership',
    properties: {
      _id: { type: String },
      account: { type: String, validators: [required] },
      location: { type: String, validators: [required] },
      shortName: { type: String, validators: [required] },
      addressLine1: {
        type: String,
        ...(requireValidation && { validators: [required] })
      },
      addressLine2: { type: String },
      addressLine3: { type: String },
      calltracksNumber: { type: String },
      calltracksId: { type: String },
      newVehicleTelephoneNumber: { type: String },
      usedVehicleTelephoneNumber: { type: String },
      newVehicleTelephoneId: { type: String },
      usedVehicleTelephoneId: { type: String },
      town: {
        type: String,
        ...(requireValidation && { validators: [required] })
      },
      county: {
        type: String,
        ...(requireValidation && { validators: [required] })
      },
      postcode: {
        type: String,
        ...(requireValidation && { validators: [required] })
      },
      lat: { type: String },
      long: { type: String },
      enquiriesTelephone: {
        type: String,
        ...(requireValidation && { validators: [required] })
      },
      serviceBookingTelephone: {
        type: String,
        ...(requireValidation && { validators: [required] })
      },
      description: { type: String },
      mondaySalesHours: { type: String },
      mondayServiceHours: { type: String },
      tuesdaySalesHours: { type: String },
      tuesdayServiceHours: { type: String },
      wednesdaySalesHours: { type: String },
      wednesdayServiceHours: { type: String },
      thursdaySalesHours: { type: String },
      thursdayServiceHours: { type: String },
      fridaySalesHours: { type: String },
      fridayServiceHours: { type: String },
      saturdaySalesHours: { type: String },
      autoLoadItBranchId: { type: String },
      saturdayServiceHours: { type: String },
      sundaySalesHours: { type: String },
      sundayServiceHours: { type: String },
      collectionHours: {
        type: Array,
        resolveType: schemata.Array(collectionSchema),
        resolve: dealer => {
          return days.map(day => {
            const hours = dealer[day + 'SalesHours'] || ''

            const canCollect =
              !!hours && !Number.isNaN(Number.parseInt(hours[0]))

            return {
              day: days.indexOf(day),
              hours,
              canCollect
            }
          })
        }
      },
      deliveryDistance: {
        type: Number,
        resolveType: Number,
        resolve: dealer => {
          const distance = dealer.deliveryDistance * 1609

          if (!defaultDeliveryDistance) {
            return 0
          }

          if (Number.isNaN(distance)) {
            return defaultDeliveryDistance * 1609
          }

          return distance
        }
      },
      slug: { type: String, validators: [required] },
      contactEmail: {
        type: String,
        ...(requireValidation && { validators: [isEmail] })
      },
      leadManagementServiceEmail: {
        type: String,
        ...(requireValidation && { validators: [isEmail] })
      },
      liveChatProviderId: { type: Number },
      createdDate: { type: Date, defaultValue: () => new Date() },
      vehicles: {
        type: Array,
        resolve: parent =>
          readMake && Promise.all(parent.vehicles.map(readMake)),
        resolveType: readMake && schemata.Array(createMakeSchema())
      },
      images: {
        type: Object,
        defaultValue: () => [],
        resolve: async dealership => resolveImages(dealership.images),
        resolveType: Array
      },
      thumbnailImages: {
        type: Object,
        defaultValue: () => [],
        resolve: async dealership => resolveImages(dealership.thumbnailImages),
        resolveType: Array
      },
      marketingImages: {
        type: Object,
        defaultValue: () => [],
        resolve: async dealership => resolveImages(dealership.marketingImages),
        resolveType: Array
      },
      mapImage: {
        type: Object,
        defaultValue: () => [],
        resolve: async dealership => resolveImages(dealership.mapImage),
        resolveType: Array
      },
      videoMapImage: {
        type: Object,
        defaultValue: () => [],
        resolve: async dealership => resolveImages(dealership.videoMapImage),
        resolveType: Array
      },
      videoMarketLeadingHeadline: { type: String },
      videoMarketLeadingPoints: { type: String },
      body: {
        type: Object,
        defaultValue: () => ({ widgets: [] }),
        validators: {
          draft: [],
          published: [],
          archived: []
        },
        resolve: async dealership => {
          return JSON.stringify(dealership.body.widgets)
        },
        resolveType: String
      },
      reviewTitle: { type: String },
      reviewText: { type: String },
      reviewCtaHref: { type: String, validators: [validateIfSet(isUrl)] },
      reviewCtaLabel: { type: String },
      autoTraderFeedId: { type: String },
      blueSkyDealerId: { type: String },
      modixKey: { type: String },
      mapsUrl: { type: String },
      reviewEntities: {
        type: Array,
        defaultValue: () => [],
        resolve: async dealership => {
          const entities = []
          if (!readReviewEntity) {
            return []
          }

          if (dealership.reviewEntities) {
            for (const reviewEntityId of dealership.reviewEntities) {
              const embellishedReviewEntity = await readReviewEntity(
                reviewEntityId
              )

              if (!embellishedReviewEntity) continue

              entities.push(embellishedReviewEntity.apiId)
            }
          }
          return entities
        },
        resolveType: Array
      },
      autoTraderUrl: { type: String },
      manufacturerUsedUrl: { type: String },
      rtcDealerId: { type: String },
      disableVehicleAllocating: { type: Boolean, defaultValue: () => false },
      prepCollectionOnly: { type: Boolean, defaultValue: () => false },
      autoTraderSlots: { type: Number },
      codeweaversDealerKey: { type: String },
      servicingAddressLine1: { type: String },
      servicingAddressLine2: { type: String },
      servicingAddressLine3: { type: String },
      servicingTown: { type: String },
      servicingCounty: { type: String },
      servicingPostcode: { type: String },
      modixUrl: { type: String },
      vwfsFinanceId: { type: String },
      complaintDefaultEmail: { type: String },
      complaintAreas: {
        type: schemata.Array(
          schemata({
            name: 'ComplaintArea',
            properties: {
              area: { type: String },
              email: { type: String }
            }
          })
        ),
        defaultValue: () => []
      }
    }
  })

  return schema
}
