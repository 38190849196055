// WARNING!
// This file is generated by pliers. Do not manually
// edit its contents. Your changes WILL be overwritten!

module.exports = initComponents

const componentLoader = require('component-loader')
let components = [
  require('../../../components/admin/account/init.js')
, require('../../../components/admin/administrator/init.js')
, require('../../../components/admin/api-error/init.js')
, require('../../../components/admin/article/init.js')
, require('../../../components/admin/asset/init.js')
, require('../../../components/admin/author/init.js')
, require('../../../components/admin/autotrader/init.js')
, require('../../../components/admin/body-type/init.js')
, require('../../../components/admin/bodyshop-submission/init.js')
, require('../../../components/admin/career/init.js')
, require('../../../components/admin/case-study/init.js')
, require('../../../components/admin/colour/init.js')
, require('../../../components/admin/customer/init.js')
, require('../../../components/admin/deal/init.js')
, require('../../../components/admin/dealership/init.js')
, require('../../../components/admin/dynavid-asset/init.js')
, require('../../../components/admin/faq/init.js')
, require('../../../components/admin/features/init.js')
, require('../../../components/admin/idealfile-upload/init.js')
, require('../../../components/admin/image-set-config/init.js')
, require('../../../components/admin/image-set/init.js')
, require('../../../components/admin/index/init.js')
, require('../../../components/admin/layout/init.js')
, require('../../../components/admin/list/init.js')
, require('../../../components/admin/make/init.js')
, require('../../../components/admin/menu-bar/init.js')
, require('../../../components/admin/model/init.js')
, require('../../../components/admin/netlify/init.js')
, require('../../../components/admin/new-vehicle-import/init.js')
, require('../../../components/admin/newsletter/init.js')
, require('../../../components/admin/offer-category/init.js')
, require('../../../components/admin/offer/init.js')
, require('../../../components/admin/person/init.js')
, require('../../../components/admin/prep-form-entry/init.js')
, require('../../../components/admin/redirect/init.js')
, require('../../../components/admin/review-entity/init.js')
, require('../../../components/admin/revision/init.js')
, require('../../../components/admin/role/init.js')
, require('../../../components/admin/scraped-vehicle/init.js')
, require('../../../components/admin/section/init.js')
, require('../../../components/admin/standard-data/init.js')
, require('../../../components/admin/style-guide/init.js')
, require('../../../components/admin/tag/init.js')
, require('../../../components/admin/used-vehicle/init.js')
, require('../../../components/admin/valuation/init.js')
, require('../../../components/admin/vehicle-finance/init.js')
, require('../../../components/admin/widget/init.js')
, require('../../../components/admin/widgets/all-dealerships/init.js')
, require('../../../components/admin/widgets/automotive-cards/init.js')
, require('../../../components/admin/widgets/bodyshop-form/init.js')
, require('../../../components/admin/widgets/button-group/init.js')
, require('../../../components/admin/widgets/careers-form/init.js')
, require('../../../components/admin/widgets/careers-list/init.js')
, require('../../../components/admin/widgets/careers-page/init.js')
, require('../../../components/admin/widgets/case-study/init.js')
, require('../../../components/admin/widgets/complaint/init.js')
, require('../../../components/admin/widgets/contact/init.js')
, require('../../../components/admin/widgets/cookie-list/init.js')
, require('../../../components/admin/widgets/event-hype-strip/init.js')
, require('../../../components/admin/widgets/faq-tags/init.js')
, require('../../../components/admin/widgets/fb-pixel/init.js')
, require('../../../components/admin/widgets/feature-carousel/init.js')
, require('../../../components/admin/widgets/floaty-promo/init.js')
, require('../../../components/admin/widgets/full-range/init.js')
, require('../../../components/admin/widgets/heading/init.js')
, require('../../../components/admin/widgets/headshot/init.js')
, require('../../../components/admin/widgets/hero-carousel/init.js')
, require('../../../components/admin/widgets/hero/init.js')
, require('../../../components/admin/widgets/homepage-hero/init.js')
, require('../../../components/admin/widgets/html/init.js')
, require('../../../components/admin/widgets/image-button-group/init.js')
, require('../../../components/admin/widgets/image/init.js')
, require('../../../components/admin/widgets/latest-articles/init.js')
, require('../../../components/admin/widgets/list/init.js')
, require('../../../components/admin/widgets/make-hero/init.js')
, require('../../../components/admin/widgets/model-banner/init.js')
, require('../../../components/admin/widgets/news-strip/init.js')
, require('../../../components/admin/widgets/newsletter-signup/init.js')
, require('../../../components/admin/widgets/offer-details-table/init.js')
, require('../../../components/admin/widgets/offer-grid/init.js')
, require('../../../components/admin/widgets/primary-actions/init.js')
, require('../../../components/admin/widgets/promo-carousel/init.js')
, require('../../../components/admin/widgets/range-by-body-type/init.js')
, require('../../../components/admin/widgets/range-carousel/init.js')
, require('../../../components/admin/widgets/recently-viewed-vehicles/init.js')
, require('../../../components/admin/widgets/reviews-carousel/init.js')
, require('../../../components/admin/widgets/reviews/init.js')
, require('../../../components/admin/widgets/search-hero/init.js')
, require('../../../components/admin/widgets/section-promo/init.js')
, require('../../../components/admin/widgets/signpost/init.js')
, require('../../../components/admin/widgets/sub-navigation/init.js')
, require('../../../components/admin/widgets/summary-reviews/init.js')
, require('../../../components/admin/widgets/text/init.js')
, require('../../../components/admin/widgets/unmissable-special-offers/init.js')
, require('../../../components/admin/widgets/used-vehicle-carousel/init.js')
, require('../../../components/admin/widgets/vehicle-carousel/init.js')
, require('../../../components/admin/widgets/vehicle-pcp-example/init.js')
, require('../../../components/admin/widgets/video/init.js')
]

function initComponents(serviceLocator) {
  componentLoader(
    components,
    function(initFn) {
      return initFn.bind(null, serviceLocator)
    },
    function(error) {
      if (error) throw error
      serviceLocator.componentLoader.emit('complete')
    }
  )
}
