const schemata = require('schemata')
const resolveImages = require('../../../../lib/image-resolver')

module.exports = () => {
  const schema = schemata({
    name: 'OptionalExtra',
    properties: {
      _id: { type: String },
      title: { type: String },
      description: { type: String },
      price: { type: Number },
      originalPrice: { type: Number },
      images: {
        type: Object,
        defaultValue: () => [],
        resolve: async extra => resolveImages(extra.images),
        resolveType: Array
      }
    }
  })

  return schema
}
