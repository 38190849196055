const DealService = require('./services/deal')

const initAdmin = () => ({ dealAdmin: [init] })

const init = (serviceLocator, cb) => {
  serviceLocator.dealService = new DealService(serviceLocator)
  cb(null)
}

module.exports = initAdmin
